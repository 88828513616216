import { configureStore } from '@reduxjs/toolkit'
import podReducer from './states/podSlice'
import dataGridReducer from './states/dataGridSlice'
import interviewerReducer from './states/interviewerSlice'
import podTypeReducer from './states/PodTypeSlice'

const store = configureStore({
  reducer: {
    pods: podReducer,
    dataGrid: dataGridReducer,
    interviewers: interviewerReducer,
    podTypes: podTypeReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store

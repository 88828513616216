import * as React from 'react'
import { Auth } from 'aws-amplify'
import { useLocation } from 'react-router-dom'
import { Typography } from '@mui/material'

/* 
  NOTE
  ================================================================================= 
  Using the location state (set in PrivateRouter Component) so we can redirect 
  back to the original page we came from before authentication.

  We pass it as customState to Cognito and once we get the response and listen for 
  the customOAuthState event we can then redirect.
  
  ** See useAuth hook for the receiving end of the call. **
  =================================================================================
*/

const FederatedSignIn: React.FC = () => {
  const location: any = useLocation()
  const from = location.state?.from?.pathname || '/'

  React.useEffect(() => {
    Auth.federatedSignIn({
      customProvider: (window as any).idpProvider.providerName,
      customState: JSON.stringify({ route: from }),
    }).catch((err) => {
      console.log(err)
    })
  })

  return (
    <Typography variant="body1">Redirecting to Amazon Login ...</Typography>
  )
}

export default FederatedSignIn

import * as React from 'react'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import Autocomplete from '@mui/material/Autocomplete'

interface AutoCompleteProps {
  label: string
  onInputChange: any
  options: string[] | undefined
  value: string | undefined
}

export const FreeSolo: React.FC<AutoCompleteProps> = ({
  value,
  label,
  options,
  onInputChange,
}) => {
  function inputChange(
    event: React.SyntheticEvent,
    value: string,
    reason: any
  ) {
    console.log('Not yet overridden', event, value, reason)
  }

  return (
    <Stack spacing={2} sx={{ width: 150 }}>
      <Autocomplete
        id="free-solo-demo"
        freeSolo
        value={value ? value : ''}
        onInputChange={onInputChange ? onInputChange : inputChange}
        options={options ? options : []}
        renderInput={(params) => <TextField {...params} label={label} />}
      />
    </Stack>
  )
}

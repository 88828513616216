import { PodApi } from '../api'
import { InterviewerInput } from '../api/types'
import { InterviewerDetails } from '../react-app-env'
import { Variant } from './feedbackSnackbar'

export function putInterviewer(
  api: PodApi,
  interviewer: InterviewerDetails
): Variant {
  const interviewerInput: InterviewerInput = {
    lastPod: interviewer.lastPod?.getTime(),
    isBr: interviewer.isBreadth,
    isMgr: interviewer.isHiringManager,
    name: interviewer.name,
    email: interviewer.email,
    jobLevel: interviewer.jobLevel,
    jobTitle: interviewer.jobTitle,
    isTech: true,
    competencies: interviewer.competencies,
  }
  try {
    api.put('Interviewer', {
      alias: interviewer.alias,
      input: interviewerInput,
    })
    return Variant.SUCCESS
  } catch (e) {
    console.error(e)
    return Variant.ERROR
  }
}

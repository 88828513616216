import {
  Candidate,
  InterviewRoleCompetency,
  InterviewerDetails,
  LDAP,
  PodDetails,
  PodType,
  Schedule,
} from '../react-app-env'
import {
  Candidate as CandidateRaw,
  InterviewType,
  Interviewer as InterviewerRaw,
  LDap as LDapRaw,
  Pod as PodRaw,
  PodType as PodTypeRaw,
  Schedule as ScheduleRaw,
} from '../api/types'
import { ReturnData } from '../api'

export function equalInterviewTypes(
  a: string | InterviewType,
  b: string | InterviewType
): boolean {
  return getInterviewType(a) === getInterviewType(b)
}

export function getInterviewType(type: string | InterviewType): InterviewType {
  switch (type) {
    case 'Hiring Manager':
    case 'HiringManager':
      return InterviewType.HiringManager
    case 'Bar Raiser':
    case 'BarRaiser':
      return InterviewType.BarRaiser
    case 'Depth':
    case 'TechDepth':
    case 'Tech Depth':
    case InterviewType.TechDepth:
      return InterviewType.TechDepth
    case 'Breadth':
    case 'TechBreadth':
    case 'Tech Breadth':
    case InterviewType.TechBreadth:
      return InterviewType.TechBreadth
    case 'Non Proserve':
    case 'NonProserve':
      return InterviewType.NonProserve
    case 'Other':
      return InterviewType.Other
    case 'LP':
      return InterviewType.LP
    default:
      console.warn('cannot identify', type, 'with getInterviewType()')
      return InterviewType.BarRaiser
  }
}

export function getInterviewTypesOfPodType(
  podTypeId: string,
  podTypes: PodType[]
) {
  const interviewTypes: InterviewType[] = []
  const podType = podTypes.find((podType) => podType.podTypeId === podTypeId)

  if (!podType) {
    console.error('Failed to add Candidates due to PodType error')
    return interviewTypes
  }
  const interviewTypeNames = Object.getOwnPropertyNames(podType.interviewTypes)
  interviewTypeNames.forEach((name) => {
    const interviewType = getInterviewType(name)
    if (interviewType) {
      interviewTypes.push(interviewType)
    }
  })
  return interviewTypes.sort()
}

export function cleanCandidate(
  input: CandidateRaw | ReturnData | null,
  schedules?: Schedule[]
): Candidate | null {
  if (input && input.candidateId) {
    const temp: Candidate = {
      hireLink: input.hireLink ? input.hireLink : '',
      candidateId: input.candidateId,
      name: input.name ? input.name : '',
      level: input.jobLevel ? input.jobLevel : 0,
      position: input.jobTitle ? input.jobTitle : '',
      schedules: schedules ? cleanSchedules(schedules) : [],
    }
    return temp
  }
  return null
}

export function cleanInterviewer(
  input: InterviewerRaw | ReturnData | null
): InterviewerDetails | null {
  if (input?.alias) {
    const temp: InterviewerDetails = {
      alias: input.alias,
      competencies: input.competencies ? input.competencies : [],
      email: input.email ? input.email : '',
      isBreadth: input.isBr ? input.isBr : false,
      isHiringManager: input.isMgr ? input.isMgr : false,
      isTechnical: input.isTech ? input.isTech : false,
      jobLevel: input.jobLevel ? input.jobLevel : 4,
      jobTitle: input.jobTitle ? input.jobTitle : '',
      lastPod: input.lastPod ? new Date(input.lastPod) : null,
      name: input.name ? input.name : '',
    }
    return temp
  }
  return null
}

export function cleanSchedule(
  input: ScheduleRaw | ReturnData | null
): Schedule | null {
  if (
    input &&
    input.candidateId &&
    input.podId &&
    input.startTime &&
    input.endTime &&
    input.interviewType &&
    input.queue
  ) {
    const temp: Schedule = {
      candidateId: input.candidateId,
      podId: input.podId,
      startTime: new Date(input.startTime),
      endTime: new Date(input.endTime),
      interviewType: getInterviewType(input.interviewType),
      queue: JSON.parse(input.queue),
      interviewer: input.interviewer ? input.interviewer : '<empty>',
      scheduleStatus: input.status,
    }
    return temp
  } else {
    return null
  }
}

export function cleanSchedules(input: ReturnData | null | void): Schedule[] {
  const schedules: Schedule[] = []
  if (input)
    input.forEach((element) => {
      const schedule = cleanSchedule(element)
      if (schedule) {
        schedules.push(schedule)
      }
    })

  return schedules
}

export function cleanPod(input: PodRaw | ReturnData | null): PodDetails | null {
  if (
    input &&
    input.contact &&
    input.endTime &&
    input.jobLevel &&
    input.podId &&
    input.podTypeId &&
    input.rcGroup &&
    input.startTime
  ) {
    const temp: PodDetails = {
      contact: input.contact,
      endTime: new Date(input.endTime),
      jobLevel: input.jobLevel,
      podId: input.podId,
      podTypeId: input.podTypeId,
      rcGroup: input.rcGroup,
      startTime: new Date(input.startTime),
    }
    return temp
  }
  return null
}

function parseInterviewTypes(input: string | null): InterviewRoleCompetency {
  const temp: InterviewRoleCompetency = {}
  if (input) {
    const obj = JSON.parse(input)
    for (const type in InterviewType) {
      if (obj[type]) {
        temp[type] = obj[type]
      }
    }
  }
  return temp
}

export function cleanPodType(
  input: PodTypeRaw | ReturnData | null
): PodType | null {
  if (input && input.podTypeId) {
    const temp: PodType = {
      active: input.active ? input.active : [],
      description: input.description ? input.description : '',
      interviewTypes: parseInterviewTypes(input.interviewTypes),
      podTypeId: input.podTypeId,
      rcGroup: input.rcGroup ? input.rcGroup : '',
    }
    return temp
  }

  return null
}

export function cleanLDap(input: LDapRaw | ReturnData | null): LDAP | null {
  if (
    input &&
    input.group &&
    input.permissionPriority &&
    input.podRole &&
    input.rcGroup
  ) {
    const temp: LDAP = {
      group: input.group,
      permissionPriority: input.permissionPriority,
      podRole: input.podRole,
      rcGroup: input.rcGroup,
    }
    return temp
  }
  return null
}

///<reference types="@types/node"/>

import React, { useCallback, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { CircularProgress } from '@mui/material'
import Public from '../views/Public'

import '../styles/loader.scss'

export interface LoaderProps {
  active: boolean
  size?: number | string
  thickness?: number
  disableShrink?: boolean
  backdrop?: Element | boolean | string
  backdropColor?: string
  clickable?: boolean
  color?: 'primary' | 'secondary' | 'error'
  timeout?: number
}

export const Loader: React.FC<LoaderProps & { children?: any }> = ({
  active,
  size,
  thickness,
  disableShrink,
  color = 'secondary',
  backdropColor = 'transparent',
  backdrop = true,
  timeout = 15,
  children,
}) => {
  const [isTimedOut, setIsTimedOut] = useState(false)
  const [timer, setTimer] = useState<NodeJS.Timeout>()

  useEffect(() => {
    setTimer(
      setTimeout(() => {
        setIsTimedOut(true)
      }, timeout * 1000)
    )
  }, [timeout])

  useEffect(() => {
    if (!active && timer) clearTimeout(timer)
  }, [active, timer])

  const getLoader = useCallback(() => {
    if (isTimedOut) {
      return <Public />
    }
    const styleProps = {
      size,
      thickness,
      disableShrink,
      color,
    }
    const Loader = (
      <div className="ps-loader">
        <CircularProgress {...styleProps} />
      </div>
    )
    let el
    if (backdrop && backdrop !== true) {
      el =
        typeof backdrop === 'string'
          ? backdrop === 'full'
            ? document.body
            : document.createElement(backdrop)
          : backdrop
    }
    const FullLoader = backdrop ? (
      <div
        className="ps-backdrop"
        style={{
          backgroundColor: backdropColor,
        }}
      >
        {Loader}
      </div>
    ) : (
      Loader
    )

    return el ? createPortal(FullLoader, el) : FullLoader
  }, [
    isTimedOut,
    backdrop,
    backdropColor,
    size,
    thickness,
    disableShrink,
    color,
  ])

  return active ? getLoader() : children || null
}

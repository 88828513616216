import React from 'react'
import { Box, Button, Container, Paper } from '@mui/material'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { PodApi } from '../../api/index'
import {
  PodSignup,
  ScheduleInput,
  Schedule as ScheduleRaw,
} from '../../api/types'
import PromisePool from '@supercharge/promise-pool'
import { cleanPod, cleanSchedule } from '../../utils/cleanClasses'
import { PodDetails, Schedule } from '../../react-app-env'
import { Variant, feedbackSnackbar } from '../../utils/feedbackSnackbar'

const api = new PodApi()

const Demo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}))

const getPod = async (podId: string): Promise<PodDetails | null> => {
  const podRaw = await api.get('PodByPodId', { podId: podId })
  const pod = cleanPod(podRaw)
  if (pod) {
    return pod
  }
  return null
}

const listSignUp = async (alias: string): Promise<PodSignup[]> => {
  return (await api.list('PodSignupByInterviewer', {
    interviewer: alias,
  })) as PodSignup[]
}

const listSchedule = async (podId: string): Promise<ScheduleRaw[]> => {
  return (await api.list('ScheduleByPodId', { podId: podId })) as ScheduleRaw[]
}

function filterAliasOut(
  schedule: Schedule,
  alias: string
): ScheduleInput | null {
  if (!schedule.queue.find((x) => x === alias)) {
    return null
  }

  const newQueue = schedule.queue.filter((i) => i !== alias)

  const input: ScheduleInput = {
    podId: schedule.podId,
    startTime: schedule.startTime.getTime(),
    endTime: schedule.endTime.getTime(),
    queue: JSON.stringify(newQueue),
    interviewer: schedule.interviewer,
  }

  return input
}

const PendingList: React.FC<{ alias: string }> = ({ alias }) => {
  const [pods, setPods] = React.useState<PodDetails[]>([])

  async function handleCancel(index) {
    const podInfo: PodDetails = pods[index]
    const schedulesRaw: ScheduleRaw[] = await listSchedule(podInfo.podId)

    const errorsPresent: string[] = []

    await PromisePool.for(schedulesRaw)
      .withConcurrency(schedulesRaw.length ? schedulesRaw.length : 1)
      .process(async (scheduleRaw) => {
        const schedule = cleanSchedule(scheduleRaw)
        if (schedule) {
          const input = filterAliasOut(schedule, alias)
          if (input) {
            await api
              .put('Schedule', {
                candidateId: schedule.candidateId,
                interviewType: schedule.interviewType,
                input: input,
              })
              .catch((error) => {
                errorsPresent.push(error)
              })
          }
        }
      })
      .catch((error) => {
        errorsPresent.push(error)
      })

    await api
      .delete('PodSignup', { podId: podInfo.podId, interviewer: alias })
      .catch((error) => {
        errorsPresent.push(error)
      })
    const tempArray: PodDetails[] = pods.map((pod) => pod)
    tempArray.splice(index, 1)
    setPods(tempArray)

    if (errorsPresent.length === 0) {
      feedbackSnackbar('Successfully Canceled Pod', Variant.SUCCESS)
    } else {
      feedbackSnackbar('Error canceling Pod', Variant.ERROR)
    }
  }

  const sortByStringDate = (a: PodDetails, b: PodDetails): number => {
    if (a.startTime && b.startTime) {
      return a.startTime.getTime() - b.startTime.getTime()
    } else return 0
  }

  async function getDataForPendingList() {
    const signUps: PodSignup[] = await listSignUp(alias)

    const podList: PodDetails[] = []
    await PromisePool.withConcurrency(5)
      .for(signUps)
      .process(async (signUp) => {
        const pod = await getPod(signUp.podId)
        if (pod) {
          podList.push(pod)
        }
      })

    const currentTime = Date.now()
    const filteredPods = podList.filter((pod) => {
      return pod.endTime.getTime() >= currentTime
    })

    filteredPods.sort(sortByStringDate)
    setPods(filteredPods)
  }

  React.useEffect(() => {
    getDataForPendingList()
  }, [])

  return (
    <Container disableGutters maxWidth="xl">
      <Paper>
        <Box sx={{ p: 2 }}>
          <Demo>
            <List>
              {pods.map((pod, index) => {
                return (
                  <ListItem
                    key={index}
                    secondaryAction={
                      <Button
                        variant="outlined"
                        color="error"
                        data-arg={index}
                        onClick={() => handleCancel(index)}
                      >
                        Cancel
                      </Button>
                    }
                    divider
                  >
                    <ListItemText
                      primary={
                        <Typography variant="h6">
                          {pod.startTime.toDateString()}
                        </Typography>
                      }
                      secondary={
                        <span
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {' '}
                          <AccessTimeIcon sx={{ fontSize: 13 }} />
                          <span style={{ marginLeft: '4px' }}>
                            {pod.startTime.toLocaleTimeString()} -{' '}
                            {pod.endTime.toLocaleTimeString()}
                          </span>
                        </span>
                      }
                    />
                  </ListItem>
                )
              })}
            </List>
          </Demo>
        </Box>
      </Paper>
    </Container>
  )
}

export default PendingList

import { InterviewType } from '../api/types'
import { Candidate, Pod, Schedule } from '../react-app-env'

export type TimeSlot = {
  endTime: Date
  startTime: Date
}

export function CalculateInterviewSlotTimes(pod: Pod): TimeSlot[] {
  let startTime = pod.startDateTime
  const breakBeforeIndex = 2
  const meetingDurationInMilliSeconds = 60 * 60 * 1000
  const beakDurationInMilliSeconds = 30 * 60 * 1000
  const timeSlots: TimeSlot[] = []

  for (let i = 0; i < 5; i++) {
    if (i == breakBeforeIndex) {
      startTime = new Date(startTime.getTime() + beakDurationInMilliSeconds)
    }
    const endTime = new Date(
      startTime.getTime() + meetingDurationInMilliSeconds
    )
    timeSlots.push({
      startTime: startTime,
      endTime: endTime,
    })

    startTime = endTime
  }
  return timeSlots
}

export function RotateTimeSlots(
  timeSlots: TimeSlot[],
  count: number
): TimeSlot[] {
  const temp = [...timeSlots]
  for (let i = 0; i < count; i++) {
    const lastElement = temp.pop()
    if (lastElement) {
      temp.splice(0, 0, lastElement)
    }
  }
  return temp
}

export function GetTimeSlotsByInterviewType(
  pod: Pod,
  interviewTypes: InterviewType[],
  rotateCount: number
): Map<InterviewType, TimeSlot> {
  const map: Map<InterviewType, TimeSlot> = new Map<InterviewType, TimeSlot>()

  const times = CalculateInterviewSlotTimes(pod)
  const timesRotated = RotateTimeSlots(times, rotateCount)

  for (let i = 0; i < interviewTypes.length; i++) {
    map[interviewTypes[i]] = timesRotated[i]
  }

  return map
}

export function sortPodCandidates(
  pod: Pod,
  typeList: (InterviewType | InterviewType)[]
) {
  const copy = [...pod.candidates]
  return copy.sort((left: Candidate, right: Candidate) => {
    const leftIndex = getCandidateIndex(left, typeList)
    const rightIndex = getCandidateIndex(right, typeList)
    return leftIndex - rightIndex
  })
}

function getCandidatesFirstInterview(
  candidate: Candidate
): InterviewType | InterviewType {
  const orderedSchedules = [...candidate.schedules]
  orderedSchedules.sort(
    (left: Schedule, right: Schedule) =>
      left.startTime.getTime() - right.startTime.getTime()
  )

  if (orderedSchedules.length < 1) {
    console.warn('cannot get first interviewer')
  }
  return orderedSchedules[0].interviewType
}

function getCandidateIndex(
  candidate: Candidate,
  typeList: (InterviewType | InterviewType)[]
): number {
  const firstInterview = getCandidatesFirstInterview(candidate)
  const index = typeList.findIndex(
    (type) => firstInterview.valueOf() == type.valueOf()
  )
  if (index < 0) {
    console.warn(
      'Could not get index of type ',
      firstInterview,
      'from array',
      typeList
    )
  }
  return index
}

export function MapCandidateOrder(
  candidates: Candidate[],
  typeList: (InterviewType | InterviewType)[]
): Map<number, Candidate> {
  const list = new Map<number, Candidate>()
  candidates.forEach((candidate) => {
    const key = getCandidateIndex(candidate, typeList)
    if (key > -1) {
      if (list.has(key)) {
        console.error(
          'Found Conflicting time for candidate',
          key,
          candidate,
          list.get(key)
        )
      }
      list.set(key, candidate)
    }
  })
  return list
}

export function getRotateTimeSlotCount(
  mappedOrder: Map<number, Candidate>,
  indexOfNewCandidate: number
): number | null {
  let rotationCount = 0
  let forIndexOfCandidate = 0

  while (rotationCount < 100) {
    if (!mappedOrder.has(rotationCount)) {
      if (indexOfNewCandidate === forIndexOfCandidate) {
        return rotationCount
      } else {
        forIndexOfCandidate += 1
      }
    }
    rotationCount += 1
  }

  console.error('Could not find rotation count')
  return null
}

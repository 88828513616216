import React from 'react'
import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import AddPodsModal, { CloseModalStruct } from '../components/AddPodsModal'
import useAuth from '../hooks/useAuth'
import { PodList } from '../components/recruiter/PodList'
import { Candidate, PodDetails, Schedule } from '../react-app-env'

import { useAppDispatch, useAppSelector } from '../hooks/useRedux'
import { clearPods, createPod } from '../states/podSlice'

import PromisePool from '@supercharge/promise-pool'
import { Pod } from '../react-app-env'
import { PodApi, api } from '../api'
import {
  Candidate as CandidateRaw,
  PodInput,
  Pod as PodRaw,
} from '../api/types'
import { getCandidateList, getPodList } from '../utils/dbQueries'
import { cleanCandidate, cleanPod } from '../utils/cleanClasses'

const createData = (
  id: string,
  startDateTime: Date,
  endDateTime: Date,
  type: string,
  jobLevel: number[],
  status: string,
  recruiter: string,
  recruiterAlias: string,
  candidates: Candidate[]
): Pod => ({
  id,
  startDateTime,
  endDateTime,
  type,
  jobLevel,
  status,
  recruiter,
  recruiterAlias,
  candidates,
})

function clearExistingData(pods: Pod[], dispatch) {
  if (pods.length > 0) {
    dispatch(clearPods())
  }
}

export async function SyncPodsWithDatabase(pods: Pod[], api: PodApi, dispatch) {
  clearExistingData(pods, dispatch)
  const PodsRaw: PodRaw[] = await getPodList(api)
  const currentPods: PodDetails[] = []
  PodsRaw.forEach((podRaw) => {
    const temp = cleanPod(podRaw)
    if (temp) {
      currentPods.push(temp)
    }
  })

  await PromisePool.withConcurrency(10)
    .for(currentPods)
    .process(async (podDetails) => {
      const candidates: CandidateRaw[] = await getCandidateList(api, podDetails)
      const candidatePool: Candidate[] = []

      await PromisePool.withConcurrency(20)
        .for(candidates)
        .process(async (candidateRaw) => {
          const interviewers: Schedule[] = []
          const candidateCreate: Candidate | null = cleanCandidate(
            candidateRaw,
            interviewers
          )
          if (candidateCreate) {
            candidatePool.push(candidateCreate)
          }

          return candidatePool
        })
        .finally(() => {
          const newPod: Pod = {
            id: podDetails.podId,
            startDateTime: podDetails.startTime,
            endDateTime: podDetails.endTime,
            type: podDetails.podTypeId,
            jobLevel: podDetails.jobLevel,
            status: 'pending',
            recruiter: podDetails.rcGroup,
            recruiterAlias: podDetails.rcGroup,
            candidates: candidatePool,
          }
          dispatch(createPod(newPod))
        })
    })
}

export const RecruiterDashboard: React.FC = () => {
  const { user } = useAuth()
  const dispatch = useAppDispatch()
  const pods = useAppSelector((state) => state.pods)
  const podTypes = useAppSelector((state) => state.podTypes)

  const [modalOpen, setModalOpen] = React.useState<boolean>(false)

  const addPod = () => {
    setModalOpen(true)
  }

  React.useEffect(() => {
    SyncPodsWithDatabase(pods, api, dispatch)
  }, [])

  const handleModalClose = (event: CloseModalStruct) => {
    if (event.action === 'save' && event.pods) {
      event.pods.map((pod) => {
        dispatch(
          createPod(
            createData(
              pod.id,
              pod.startDate,
              pod.endDate,
              pod.type,
              pod.levels,
              'CREATED',
              `${user?.firstName} ${user?.lastName}`,
              `${user?.email.split('@')[0]}`,
              []
            )
          )
        )

        //Hardcoded to seanfi for now
        const savePodInput: PodInput = {
          podTypeId: pod.type,
          rcGroup: 'seanfi',
          startTime: pod.startDate.getTime(),
          endTime: pod.endDate.getTime(),
          jobLevel: pod.levels,
          contact: `${user?.email}`,
        }

        if (pod.type.length > 0) {
          api.put('Pod', { podId: pod.id, input: savePodInput })
        }
      })
    }
    setModalOpen(false)
    console.log('modal closed1')
  }

  return (
    <>
      <AddPodsModal isOpen={modalOpen} onClose={handleModalClose} />

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '15px 0 15px 0',
        }}
      >
        <h2>Recruiter Dashboard</h2>
        <Button
          aria-label="Add Pod(s)"
          variant="outlined"
          onClick={addPod}
          size="small"
        >
          <AddIcon /> Add
        </Button>
      </div>
      <PodList podProp={pods} />
    </>
  )
}

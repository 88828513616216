import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { PodType } from '../react-app-env'

const initialState: PodType[] = []

export const podTypeSlice = createSlice({
  name: 'podTypes',
  initialState,
  reducers: {
    setState: (state, action: PayloadAction<PodType[]>) => {
      return action.payload
    },
  },
})

export const { setState } = podTypeSlice.actions

export default podTypeSlice.reducer

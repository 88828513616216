import { useLocation } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../hooks/useRedux'
import { CompetencyDisplay } from './CompetencyDisplay'
import React, { useState } from 'react'
import store from '../store'
import { InterviewerDetails as Interviewer, PodType } from '../react-app-env'
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Link,
  Stack,
  Typography,
} from '@mui/material'
import EmailIcon from '@mui/icons-material/Email'
import { PodApi } from '../api'
import { putInterviewer } from '../utils/dbMutations'
import {
  setIsBreadth,
  setIsHiringManager,
  setIsTechnical,
} from '../states/interviewerSlice'
import { displayFeedback } from '../utils/feedbackSnackbar'
import useAuth from '../hooks/useAuth'

function listCompetencies(podTypes: PodType[]): string[] {
  return podTypes.map((type) => type.podTypeId)
}

function removeCompetencies(
  competencies: string[],
  toRemove: string[]
): string[] {
  const filteredCompetencies: string[] = []
  competencies.forEach((competency) => {
    const shouldIgnore = toRemove.find((ignore) => ignore === competency)
    if (!shouldIgnore) {
      filteredCompetencies.push(competency)
    }
  })
  return filteredCompetencies
}

interface payload {
  type: string
  value: any
}

function setAttribute(
  api: PodApi,
  state: Interviewer,
  dispatch: any,
  action: payload
) {
  const copy = { ...state }
  copy[action.type] = action.value
  return putInterviewer(api, copy)
}

const displayInterviewer = (interviewer: Interviewer | undefined) => {
  if (!interviewer) return
  return (
    <Box sx={{ marginTop: 6 }}>
      <Stack direction="row" justifyContent="flex-start" spacing={1}>
        <Box
          component="img"
          sx={{ borderRadius: '16px' }}
          alt="PhoneTool Image"
          width="50"
          src={`https://badgephotos.corp.amazon.com/?uid=${interviewer.alias}`}
        />
        <Box sx={{ marginLeft: 4 }}>
          <Typography variant="h3" sx={{ marginTop: 1 }}>
            {interviewer.name}
          </Typography>
          <Typography variant="h5" sx={{ marginLeft: 4, marginTop: 1 }}>
            {interviewer.jobTitle}, (L{interviewer.jobLevel})
          </Typography>
          <Box sx={{ marginLeft: 4 }}>
            <EmailIcon sx={{ mr: 1, fontSize: 'small' }} />
            <Link sx={{}} href={`mailto:${interviewer.email}`}>
              {interviewer.email}
            </Link>
          </Box>
        </Box>
      </Stack>
    </Box>
  )
}

export const InterviewerDetails: React.FC = () => {
  const { user } = useAuth()
  const apiRef = React.useRef(new PodApi())
  const dispatch = useAppDispatch()
  const podTypes = store.getState().podTypes
  const location: any = useLocation()
  const interviewers = useAppSelector((state) => state.interviewers)
  const interviewer = interviewers.find(
    (interviewer) => interviewer.alias === location.state.alias
  )
  const [competencies, setCompetencies] = useState<string[]>([])
  const [availableCompetencies, setAvailableCompetencies] = useState<string[]>(
    []
  )

  React.useEffect(() => {
    if (interviewer) {
      setCompetencies(interviewer.competencies)
      const allCompetencies = listCompetencies(podTypes)
      const available = removeCompetencies(
        allCompetencies,
        interviewer?.competencies
      )
      setAvailableCompetencies(available)

      // Update USER competencies if editing themselves
      if (user?.alias === interviewer.alias) {
        user.competencies = interviewer.competencies
      }
    }
  }, [interviewer?.competencies])

  const displayCheckboxes = (interviewer: Interviewer | undefined) => {
    if (!interviewer) return
    return (
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={interviewer.isHiringManager} />}
          label="Hiring Manager"
          onClick={() => {
            const newValue = !interviewer.isHiringManager
            const result = setAttribute(apiRef.current, interviewer, dispatch, {
              type: 'isHiringManager',
              value: newValue,
            })
            displayFeedback(
              result,
              'successfully updated if hiring manager',
              'failed to update if hiring manager'
            )
            dispatch(
              setIsHiringManager({
                alias: interviewer.alias,
                isHiringManager: newValue,
              })
            )
          }}
        />
        <FormControlLabel
          control={<Checkbox checked={interviewer.isTechnical} />}
          label="Technical"
          onClick={() => {
            const newValue = !interviewer.isTechnical
            const result = setAttribute(apiRef.current, interviewer, dispatch, {
              type: 'isTechnical',
              value: newValue,
            })
            displayFeedback(
              result,
              'successfully updated if technical',
              'failed to update if technical'
            )
            dispatch(
              setIsTechnical({
                alias: interviewer.alias,
                isTechnical: newValue,
              })
            )
          }}
        />
        <FormControlLabel
          control={<Checkbox checked={interviewer.isBreadth} />}
          label="Breadth"
          onClick={() => {
            const newValue = !interviewer.isBreadth
            const result = setAttribute(apiRef.current, interviewer, dispatch, {
              type: 'isBreadth',
              value: newValue,
            })
            displayFeedback(
              result,
              'successfully updated if technical',
              'failed to update if technical'
            )
            dispatch(
              setIsBreadth({
                alias: interviewer.alias,
                isBreadth: newValue,
              })
            )
          }}
        />
      </FormGroup>
    )
  }

  return (
    <>
      {displayInterviewer(interviewer)}
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ pt: 5, pl: 10, pr: 10 }}
      >
        {displayCheckboxes(interviewer)}
        <Box>
          <Typography variant="h5" align="center" sx={{ mb: 2 }}>
            Assigned Competencies
          </Typography>
          <CompetencyDisplay
            alias={interviewer ? interviewer.alias : ''}
            competencies={competencies}
          />
        </Box>
      </Stack>
    </>
  )
}

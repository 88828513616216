import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { InterviewerDetails } from '../react-app-env'

const initialState: InterviewerDetails[] = []

export interface SetCompetenciesPayload {
  alias: string
  competencies: string[]
}

export interface SetIsBreadthPayload {
  alias: string
  isBreadth: boolean
}

export interface SetIsHiringManagerPayload {
  alias: string
  isHiringManager: boolean
}

export interface SetIsTechnicalPayload {
  alias: string
  isTechnical: boolean
}

export const interviewerSlice = createSlice({
  name: 'interviewers',
  initialState,
  reducers: {
    createInterviewer: (state, action: PayloadAction<InterviewerDetails>) => {
      return [...state, action.payload]
    },
    setState: (state, action: PayloadAction<InterviewerDetails[]>) => {
      return action.payload
    },
    setCompetencies: (state, action: PayloadAction<SetCompetenciesPayload>) => {
      const interviewer = state.find(
        (interviewer) => interviewer.alias === action.payload.alias
      )
      if (interviewer) {
        interviewer.competencies = action.payload.competencies
      }
    },
    setIsBreadth: (state, action: PayloadAction<SetIsBreadthPayload>) => {
      const interviewer = state.find(
        (interviewer) => interviewer.alias === action.payload.alias
      )
      if (interviewer) {
        interviewer.isBreadth = action.payload.isBreadth
      }
    },
    setIsHiringManager: (
      state,
      action: PayloadAction<SetIsHiringManagerPayload>
    ) => {
      const interviewer = state.find(
        (interviewer) => interviewer.alias === action.payload.alias
      )
      if (interviewer) {
        interviewer.isHiringManager = action.payload.isHiringManager
      }
    },
    setIsTechnical: (state, action: PayloadAction<SetIsTechnicalPayload>) => {
      const interviewer = state.find(
        (interviewer) => interviewer.alias === action.payload.alias
      )
      if (interviewer) {
        interviewer.isTechnical = action.payload.isTechnical
      }
    },
  },
})

export const {
  createInterviewer,
  setCompetencies,
  setState,
  setIsBreadth,
  setIsHiringManager,
  setIsTechnical,
} = interviewerSlice.actions

export default interviewerSlice.reducer

import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Auth, Hub } from 'aws-amplify'
import { useLocation, useNavigate } from 'react-router-dom'
import { getConsole } from '../utils'
import { api } from '../api'
import { PodUser } from '../api/user'
import { SitePaths } from '../routes'
import {
  Variant,
  displayFeedback,
  feedbackSnackbar,
} from '../utils/feedbackSnackbar'
import { getInterviewer } from '../utils/dbQueries'
import { InterviewerDetails } from '../react-app-env'
import { putInterviewer } from '../utils/dbMutations'

const console = getConsole()

let count = 0

interface AuthContextType {
  api: any
  error?: any
  loading: boolean
  user?: PodUser
  userRole?: string
  whitelabel?: string
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType)

async function updateUserInInterviewerTable(
  user: PodUser | undefined,
  userRole
) {
  if (!user || !userRole) return

  if (user.isAuthenticated(userRole)) {
    const interviewer: InterviewerDetails = {
      alias: user.alias,
      competencies: [],
      email: user.email,
      isBreadth: false,
      isHiringManager: user.isManager,
      isTechnical: false,
      jobLevel: user.jobLevel,
      jobTitle: user.jobTitle,
      lastPod: null,
      name: user.name,
    }

    const interviewerDb = await getInterviewer(api, user.alias)
    if (interviewerDb) {
      interviewer.competencies = interviewerDb.competencies
      interviewer.lastPod = interviewerDb.lastPod
      interviewer.isBreadth = interviewerDb.isBreadth
      interviewer.isTechnical = interviewerDb.isTechnical
    }

    const result = putInterviewer(api, interviewer)
    displayFeedback(
      result,
      'User is authenticated',
      'Failed to update backend with user data'
    )
  } else {
    feedbackSnackbar('user is not authenticated', Variant.WARNING)
  }
}

// Export the provider as we need to wrap the entire app with it
export function AuthProvider({
  children,
}: {
  children: ReactNode
}): JSX.Element {
  const [user, setUser] = useState<PodUser | undefined>()
  const [error, setError] = useState<any>()
  const [loading, setLoading] = useState(true)
  const [whitelabel, setWhitelabel] = useState('')
  const [userRole, setUserRole] = useState('')
  const location = useLocation()
  const navigate = useNavigate()

  const onRole = useCallback((role, group, user) => {
    // console.log('USER CHANGED', user);
    setWhitelabel(group)
    setUserRole(role)
  }, [])

  useEffect(() => {
    updateUserInInterviewerTable(user, userRole)
  }, [user, userRole])

  // If we change page, reset the error state.
  useEffect(() => {
    setError(null)
  }, [location.pathname])

  // Check if there is a currently active session
  // when the provider is mounted for the first time.
  //
  // If there is an error, it means there is no session.
  //
  // Finally, just signal the component that the initial load
  // is over.
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((_user) => {
        setUser(new PodUser(_user, onRole))
      })
      .catch((_error) => {
        if (!count) {
          count++
          setLoading(false)
          navigate(SitePaths.Login)
        } else {
          console.error(_error)
          setError(_error)
        }
      })
  }, [])

  function handleCustomOAuthState(data: any): string {
    const routeData = JSON.parse(data)
    // console.log('ROUTE DATA', routeData);
    if (routeData && routeData.route) {
      return routeData.route
    } else {
      return '/'
    }
  }

  useEffect(() => {
    Hub.listen('auth', async (data: any) => {
      switch (data.payload.event) {
        case 'signIn':
          setLoading(true)
          // console.log('Inside signIn');
          await Auth.currentAuthenticatedUser()
            .then((_data) => {
              setUser(new PodUser(_data, onRole))
            })
            .catch((error) => {
              console.error(error)
              setError(error)
            })
            .finally(() => setLoading(false))
          break
        case 'signUp':
          break
        case 'signOut':
          setUser(undefined)
          break
        case 'signIn_failure':
          break
        case 'tokenRefresh':
          break
        case 'tokenRefresh_failure':
          break
        case 'configured':
          break
        case 'customOAuthState':
          navigate(handleCustomOAuthState(data.payload.data))
          break
        case 'oAuthSignOut':
          // console.log('useAuth -> oAuthSignOut Event')
          break
        default:
        // console.log(data.payload);
      }
    })
  }, [])

  useEffect(() => {
    if (userRole) {
      setLoading(false)
      navigate('/')
    }
  }, [userRole])

  // Make the provider update only when it should.
  // We only want to force re-renders if the user,
  // loading or error states change.
  //
  // Whenever the `value` passed into a provider changes,
  // the whole tree under the provider re-renders, and
  // that can be very costly! Even in this case, where
  // you only get re-renders when logging in and out
  // we want to keep things very performant.
  const memoedValue = useMemo(
    () => ({
      user,
      api,
      whitelabel,
      userRole,
      loading,
      error,
    }),
    [user, whitelabel, loading, error, userRole]
  )

  // We only want to render the underlying app after we
  // assert for the presence of a current user.
  return (
    <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>
  )
}

// Let's only export the `useAuth` hook instead of the context.
// We only want to use the hook directly and never the context component.
export default function useAuth() {
  return useContext(AuthContext)
}

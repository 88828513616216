import React from 'react'
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material'
import { useState } from 'react'
import { PodType } from '../react-app-env'
import store from '../store'
import CompareArrowsIcon from '@mui/icons-material/CompareArrows'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { useAppDispatch } from '../hooks/useRedux'
import { setCompetencies } from '../states/interviewerSlice'
import { useLazyRef } from '../utils/useLazyRef'
import { PodApi } from '../api'
import { putInterviewer } from '../utils/dbMutations'
import { Variant, displayFeedback } from '../utils/feedbackSnackbar'

export interface CompetencyDisplayProps {
  alias: string
  competencies: string[]
}

enum CompetencyButtonState {
  None,
  Add,
  Remove,
}

function listCompetencies(podTypes: PodType[]): string[] {
  return podTypes.map((type) => type.podTypeId)
}

function removeCompetencies(
  competencies: string[],
  toRemove: string[]
): string[] {
  const filteredCompetencies: string[] = []
  competencies.forEach((competency) => {
    const shouldIgnore = toRemove.find((ignore) => ignore === competency)
    if (!shouldIgnore) {
      filteredCompetencies.push(competency)
    }
  })
  return filteredCompetencies
}

function renderRow(
  name: string,
  selected: boolean,
  relatedState: CompetencyButtonState,
  handleClick
) {
  return (
    <ListItem key={name} component="div" disablePadding>
      <ListItemButton
        selected={selected}
        onClick={() => handleClick(name, relatedState)}
      >
        <ListItemText primary={name} />
      </ListItemButton>
    </ListItem>
  )
}

function DisplayCompetencyList(
  competencies: string[],
  selected: string | null,
  relatedState: CompetencyButtonState,
  handleClick
) {
  return (
    <List
      sx={{
        p: 0,
        m: 0,
        width: '100%',
        height: '100%',
        bgcolor: 'background.paper',
        position: 'relative',
        overflow: 'auto',
        maxHeight: 400,
        maxWidth: 250,
        minWidth: 200,
      }}
    >
      {competencies.map((competency) =>
        renderRow(
          competency,
          competency === selected,
          relatedState,
          handleClick
        )
      )}
    </List>
  )
}

function modifyCompetency(
  competencies: string[],
  element: string,
  action: CompetencyButtonState
) {
  const temp = [...competencies]
  if (CompetencyButtonState.Add === action) {
    temp.push(element)
    return temp
  } else if (CompetencyButtonState.Remove === action) {
    return temp.filter((competency) => competency != element)
  }
  return temp
}

function saveCompetencyToBackend(api: PodApi, alias: string) {
  const interviewer = store
    .getState()
    .interviewers.find((interviewer) => interviewer.alias === alias)
  let result = Variant.WARNING
  if (interviewer) {
    result = putInterviewer(api, interviewer)
  }
  displayFeedback(
    result,
    `Successfully updated record for ${interviewer?.name}`,
    `Failed to update state for ${alias}`,
    `Failed to locate ${alias} record locally`
  )
}

const modifyCompetencyButton = (state: CompetencyButtonState, handleClick) => {
  switch (state) {
    case CompetencyButtonState.None: {
      return (
        <Button variant="contained" startIcon={<CompareArrowsIcon />} disabled>
          Select
        </Button>
      )
    }
    case CompetencyButtonState.Add: {
      return (
        <Button
          variant="contained"
          startIcon={<KeyboardArrowLeftIcon />}
          endIcon={<KeyboardArrowLeftIcon />}
          onClick={handleClick}
        >
          Add
        </Button>
      )
    }
    case CompetencyButtonState.Remove:
      return (
        <Button
          variant="contained"
          startIcon={<KeyboardArrowRightIcon />}
          endIcon={<KeyboardArrowRightIcon />}
          onClick={handleClick}
        >
          Remove
        </Button>
      )
  }
}

export const CompetencyDisplay: React.FC<CompetencyDisplayProps> = ({
  alias,
  competencies,
}) => {
  const dispatch = useAppDispatch()
  const apiRef = useLazyRef(() => new PodApi())
  const [selected, setSelected] = useState<null | string>(null)
  const [btnState, setButtonState] = useState<CompetencyButtonState>(
    CompetencyButtonState.None
  )
  const availableCompetencies = removeCompetencies(
    listCompetencies(store.getState().podTypes),
    competencies
  )

  function handleCompetencyChange(newSelected: string) {
    if (newSelected == selected) {
      setSelected(null)
    } else {
      setSelected(newSelected)
    }
  }

  const buttonClickHandler = () => {
    if (!selected) return
    const newList = modifyCompetency(competencies, selected, btnState)
    dispatch(setCompetencies({ alias: alias, competencies: newList }))

    saveCompetencyToBackend(apiRef.current, alias)
  }

  React.useEffect(() => {
    const found = competencies.find((c) => c === selected)
    if (found) {
      setButtonState(CompetencyButtonState.Remove)
    } else if (selected) {
      setButtonState(CompetencyButtonState.Add)
    } else {
      setButtonState(CompetencyButtonState.None)
    }
  }, [competencies, selected])

  return (
    <div>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gap: 1,
          maxWidth: 550,
        }}
      >
        <Box sx={{ gridRow: '2', gridColumn: '1' }}>
          {DisplayCompetencyList(
            competencies,
            selected,
            CompetencyButtonState.Remove,
            handleCompetencyChange
          )}
        </Box>
        <Box
          sx={{
            gridRow: '2',
            gridColumn: '2',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          {modifyCompetencyButton(btnState, buttonClickHandler)}
        </Box>

        <Box sx={{ gridRow: '2', gridColumn: '3' }}>
          {DisplayCompetencyList(
            availableCompetencies,
            selected,
            CompetencyButtonState.Add,
            handleCompetencyChange
          )}
        </Box>
      </Box>
    </div>
  )
}

import { Divider } from '@mui/material'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { SitePaths } from '../routes'

const Home: React.FC = () => {
  return (
    <div>
      <h1>Hire and Develop the Best</h1>
      <p>
        Leaders raise the performance bar with every hire and promotion. They
        recognize exceptional talent, and willingly move them throughout the
        organization. Leaders develop leaders and take seriously their role in
        coaching others. We work on behalf of our people to invent mechanisms
        for development like Career Choice.
      </p>
      <p>As an individual contributor do you …</p>
      <ul>
        <li>
          Take the interviewing process seriously by understanding the job and
          focusing on hiring people who we can learn from and who will raise the
          high performance bar?
        </li>
        <li>
          Drive your own development and learning by actively discussing career
          goals, strengths and areas for development and pursuing activities
          that support your growth?
        </li>
        <li>
          Invest in the growth of others by taking ownership to mentor and guide
          them when you have expertise in a particular area?
        </li>
      </ul>

      <Divider />
      <h4>
        Already Trained and Certified -{' '}
        <Link to={SitePaths.Interviewer}>Sign up for Pods</Link>
      </h4>
    </div>
  )
}

export default Home

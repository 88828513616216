import * as React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { styled } from '@mui/material/styles'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import { Accordion, Button, CircularProgress } from '@mui/material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { InterviewType, Pod as PodRaw, ScheduleInput } from '../../api/types'
import PromisePool from '@supercharge/promise-pool'
import useAuth from '../../hooks/useAuth'
import { PodDetails, PodType, Schedule } from '../../react-app-env'
import { cleanPod, cleanSchedules } from '../../utils/cleanClasses'
import { Variant, feedbackSnackbar } from '../../utils/feedbackSnackbar'
import {
  getPodTypes,
  listSchedulesByPodIdAndByInterviewType,
} from '../../utils/dbQueries'
import { PodApi } from '../../api'
import store from '../../store'
import { renderWithSpacing } from '../../utils/displayUtils'

const Demo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

type SignUpDetails = {
  interviewType: InterviewType
  pod: PodDetails
  queueLength: number
  registered: boolean
}

const makeAccordionKey = (pod: PodDetails) => {
  return pod.startTime.toLocaleDateString()
}

function hasCompetency(allowedList: string[], hasList: string[]): boolean {
  if (allowedList.length == 0) {
    return true
  }

  return allowedList.some((allowed) => {
    return hasList.some((has) => allowed === has)
  })
}

async function getSignUpDetailsForPod(
  api: PodApi,
  pod: PodDetails,
  podType: PodType,
  userAlias: string,
  competencies: string[]
): Promise<SignUpDetails[]> {
  const signUps: SignUpDetails[] = []

  const allSchedules = cleanSchedules(
    await api.list('ScheduleByPodId', { podId: pod.podId })
  )
  podType.active.forEach((interviewType) => {
    const schedulesByType = allSchedules.filter(
      (schedule) => schedule.interviewType.valueOf() == interviewType.valueOf()
    )
    if (schedulesByType.length > 0) {
      const allowedCompetencies = podType.interviewTypes[interviewType]
      const allowed = hasCompetency(allowedCompetencies, competencies)

      const registered = schedulesByType[0].queue.find(
        (alias) => alias === userAlias
      )
        ? true
        : false
      const queueLength = schedulesByType[0].queue.length

      const podInfo: SignUpDetails = {
        pod: pod,
        queueLength: queueLength,
        registered: registered,
        interviewType: interviewType,
      }
      if (allowed || registered) {
        signUps.push(podInfo)
      }
    }
  })
  return signUps
}

const sendEmailOnSignup = async (
  api: PodApi,
  signUpInfo: SignUpDetails,
  alias: string,
  email: string
) => {
  const notUsedForEmail = '[]'

  const emailInput: ScheduleInput = {
    podId: signUpInfo.pod.podId,
    startTime: signUpInfo.pod.startTime.getTime(),
    endTime: signUpInfo.pod.endTime.getTime(),
    queue: notUsedForEmail,
    interviewer: '<empty>',
  }

  api.send('EmailOnSignup', {
    input: {
      alias: alias,
      email: email,
      input: emailInput,
      podType: signUpInfo.interviewType,
    },
  })
}

async function SaveSchedule(
  api: PodApi,
  signUpInfo: SignUpDetails,
  schedule: Schedule
) {
  const scheduleInput: ScheduleInput = {
    podId: signUpInfo.pod.podId,
    startTime: schedule.startTime.getTime(),
    endTime: schedule.endTime.getTime(),
    queue: JSON.stringify(schedule.queue),
    interviewer: schedule.interviewer,
  }

  api.put('Schedule', {
    candidateId: schedule.candidateId,
    interviewType: schedule.interviewType,
    input: scheduleInput,
  })
}

const PodList: React.FC = () => {
  const api = new PodApi()
  const [expanded, setExpanded] = React.useState<string | false>('panel1')
  const [dataLoaded, setDataLoaded] = React.useState(false)
  const [accordionData, setAccordionData] = React.useState<
    Map<string, SignUpDetails[]>
  >(new Map<string, SignUpDetails[]>())

  const { user } = useAuth()
  if (!user) {
    Error('Cannot load Auth()')
    return <div></div>
  }
  const email = user.email

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  const getValidTypes = (
    userCompetencies: string[],
    podTypes: PodType[]
  ): Set<string> => {
    const userTypes = new Set<string>()

    podTypes.forEach((podType) => {
      podType.active.map((activeInterviewType) => {
        podType.interviewTypes[activeInterviewType].forEach((competency) => {
          if (userCompetencies.find((x) => x === competency)) {
            userTypes.add(podType.podTypeId)
            return
          }
        })
      })
    })
    return userTypes
  }

  const getPodListByUserType = async (
    userTypes: string[],
    api: PodApi
  ): Promise<PodDetails[]> => {
    const podList: PodDetails[] = []
    await PromisePool.withConcurrency(2)
      .for(userTypes)
      .process(async (userType) => {
        const result = (await api.list('PodByPodTypeId', {
          podTypeId: userType,
        })) as PodRaw[]

        result.forEach((podRaw) => {
          const pod = cleanPod(podRaw)
          if (pod) {
            podList.push(pod)
          }
        })
      })
    return podList
  }

  const filterByJobLevel = (
    podList: PodDetails[],
    jobLevel: number | undefined | null
  ): PodDetails[] => {
    return podList.filter(
      (pod) => pod.jobLevel && jobLevel && Math.max(...pod.jobLevel) <= jobLevel
    )
  }

  const filterExpiredPods = (podList: PodDetails[]): PodDetails[] => {
    return podList.filter(
      (pod) => pod.startTime && pod.startTime.getTime() > Date.now()
    )
  }

  const constructAccordionData = async (
    podList: PodDetails[],
    userAlias: string,
    competencies: string[],
    api: PodApi
  ): Promise<Map<string, SignUpDetails[]>> => {
    const accordionPreData: Map<string, SignUpDetails[]> = new Map<
      string,
      SignUpDetails[]
    >()
    const count = podList.length ? podList.length : 1
    await PromisePool.withConcurrency(count)
      .for(podList)
      .process(async (pod) => {
        const podType = store
          .getState()
          .podTypes.find((type) => type.podTypeId === pod.podTypeId)
        if (podType) {
          const signUpList = await getSignUpDetailsForPod(
            api,
            pod,
            podType,
            userAlias,
            competencies
          )

          if (signUpList.length > 0) {
            const podDateString = makeAccordionKey(pod)
            if (accordionPreData.has(podDateString)) {
              accordionPreData.get(podDateString)?.concat(signUpList)
            } else {
              accordionPreData.set(podDateString, signUpList)
            }
          }
        }
      })
    return accordionPreData
  }

  React.useEffect(() => {
    async function fetchData() {
      const podTypes = await getPodTypes(api)

      if (user && podTypes) {
        const userTypes = getValidTypes(user.competencies, podTypes)
        let podList: PodDetails[] = await getPodListByUserType(
          Array.from(userTypes),
          api
        )
        podList = filterByJobLevel(podList, user.jobLevel)
        podList = filterExpiredPods(podList)

        const accordionPreData: Map<string, SignUpDetails[]> =
          await constructAccordionData(
            podList,
            user.alias,
            user.competencies,
            api
          )
        setAccordionData(accordionPreData)
        setDataLoaded(true)
      }
    }
    fetchData()
  }, [user])

  function updateSingleSignUpDetails(updated: SignUpDetails) {
    const temp = new Map(accordionData)

    const key = makeAccordionKey(updated.pod)
    const list = temp.get(key)
    if (!list) return

    const newList = list.map((signUp) => {
      if (
        updated.interviewType === signUp.interviewType &&
        updated.pod.podId === signUp.pod.podId
      ) {
        return updated
      }
      return signUp
    })

    temp.set(key, newList)

    setAccordionData(temp)
  }

  const signUpForPod = async (alias: string, signUpInfo: SignUpDetails) => {
    const errors: string[] = []
    const schedulesOfType = await listSchedulesByPodIdAndByInterviewType(
      api,
      signUpInfo.pod.podId,
      signUpInfo.interviewType
    )

    api
      .put('PodSignup', { podId: signUpInfo.pod.podId, interviewer: alias })
      .catch((error) => errors.push(error))

    schedulesOfType.forEach((schedule) => {
      schedule.queue.push(alias)
      SaveSchedule(api, signUpInfo, schedule).catch((error) =>
        errors.push(error)
      )
    })

    try {
      sendEmailOnSignup(api, signUpInfo, alias, email)
    } catch (e) {
      errors.push('Failed to send Email for sign up')
    }

    if (errors.length === 0) {
      signUpInfo.registered = true
      updateSingleSignUpDetails(signUpInfo)
      feedbackSnackbar('Signed up for Pod!', Variant.SUCCESS)
    } else {
      errors.forEach((error) => console.error(error))
      feedbackSnackbar('Error signing up for pod', Variant.ERROR)
    }
  }

  const sortByStringDate = (
    a: [string, SignUpDetails[]],
    b: [string, SignUpDetails[]]
  ): number => {
    return new Date(a[0]).getTime() - new Date(b[0]).getTime()
  }

  const podDisplay = () => {
    return (
      <>
        {Array.from(accordionData)
          .sort(sortByStringDate)
          .map(([key, value], index) => {
            return (
              <Accordion
                key={index}
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}
              >
                <MuiAccordionSummary
                  aria-controls={`panel${index}d-content`}
                  id={`panel${index}d-header`}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                      alignItems: 'center',
                    }}
                    key={key}
                  >
                    <Typography variant="h5" sx={{ fontWeight: '300' }}>
                      <CalendarTodayIcon fontSize="small" />
                      {key}{' '}
                    </Typography>
                    <div style={{ textAlign: 'center' }}>
                      <Typography variant="h5" sx={{ fontWeight: '200' }}>
                        {value.length}
                      </Typography>
                      <Typography variant="body2" sx={{ fontWeight: '200' }}>
                        count
                      </Typography>
                    </div>
                  </div>
                </MuiAccordionSummary>
                <AccordionDetails>
                  <Demo>
                    <List>
                      {value.map((signUpInfo) => {
                        let secondaryAction
                        if (signUpInfo.registered) {
                          secondaryAction = (
                            <Button disabled variant="outlined">
                              Registered
                            </Button>
                          )
                        } else if (signUpInfo.queueLength > 9) {
                          secondaryAction = (
                            <Button disabled variant="outlined">
                              Full
                            </Button>
                          )
                        } else {
                          secondaryAction = (
                            <Button
                              variant="outlined"
                              onClick={(e) =>
                                signUpForPod(user?.alias, signUpInfo)
                              }
                            >
                              Sign Up
                            </Button>
                          )
                        }
                        return (
                          <ListItem
                            key={
                              signUpInfo.pod.podId +
                              `_` +
                              signUpInfo.interviewType
                            }
                            secondaryAction={secondaryAction}
                            divider
                          >
                            <ListItemText
                              primary={
                                renderWithSpacing(signUpInfo.pod.podTypeId) +
                                `: ` +
                                renderWithSpacing(signUpInfo.interviewType)
                              }
                              secondary={`${new Date(
                                signUpInfo.pod.startTime
                              ).toLocaleTimeString()} - ${new Date(
                                signUpInfo.pod.endTime
                              ).toLocaleTimeString()}`}
                            />
                          </ListItem>
                        )
                      })}
                    </List>
                  </Demo>
                </AccordionDetails>
              </Accordion>
            )
          })}
      </>
    )
  }

  const render = () => {
    if (user && user.competencies.length < 1) {
      feedbackSnackbar('No competencies found with user', Variant.WARNING, true)
      return (
        <div>
          Uh Oh! We don't have any competencies on record for you. Please reach
          out to your manager to be assigned competencies!
        </div>
      )
    } else if (accordionData.size > 0) {
      return podDisplay()
    } else if (dataLoaded) {
      return (
        <div>
          No pods found for your competencies:{' '}
          {user?.competencies.map((competency) => (
            <li>{competency}</li>
          ))}
        </div>
      )
    } else {
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      )
    }
  }

  return render()
}

export default PodList

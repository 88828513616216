import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { renderJobLevel, renderTimeRange } from '../../utils'
import { Pod } from '../../react-app-env'
import {
  DataGrid,
  GridColDef,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridRenderCellParams,
  GridSortModel,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid'
import { Paper, TableContainer } from '@mui/material'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../hooks/useRedux'
import {
  setColumnModel,
  setFilterModel,
  setSortModel,
} from '../../states/dataGridSlice'
import { RootState } from '../../store'

interface Props {
  podProp: Pod[]
}

export const PodList: React.FC<Props> = ({ podProp: data }) => {
  const [pods, setPods] = useState<Pod[]>()
  const dispatch = useAppDispatch()
  const columns: GridColDef[] = [
    {
      field: 'id',
      flex: 12,
      hideable: true,
    },

    {
      field: 'startDateTime',
      headerName: 'Date',
      flex: 4,
      valueFormatter: (params: GridValueFormatterParams) =>
        `${params.value.toLocaleString().split(',')[0]}`,
    },
    {
      field: 'Time',
      headerName: 'Time',
      flex: 8,
      valueGetter: (params: GridValueGetterParams) =>
        `${
          renderTimeRange(params.row.startDateTime, params.row.endDateTime) ||
          ''
        }`,
      sortable: false,
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 4,
    },
    {
      field: 'jobLevel',
      headerName: 'Job Level',
      flex: 4,
      renderCell: (params: GridRenderCellParams) => {
        return params.row.jobLevel.map((level) => {
          return <div key={level}> {renderJobLevel(level)}</div>
        })
        //`${renderJobLevels(params.value)}`,
      },
    },
    {
      field: 'candidateTotal',
      headerName: 'Candidates',
      flex: 4,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.candidates.length,
    },
    {
      field: 'recruiter',
      headerName: 'Recruiter',
      flex: 5,
      renderCell(params) {
        return (
          <Link
            to={`https://phonetool.amazon.com/users/${params.row.recruiterAlias}`}
            target="_blank"
          >
            {params.row.recruiter}
          </Link>
        )
      },
    },
    { field: 'status', flex: 4, headerName: 'Status' },
    {
      field: 'edit',
      headerName: 'Edit',
      flex: 2,
      renderCell(params) {
        return (
          <Link
            to={`/secure/recruiter/pod/${params.row.id}`}
            state={{
              pod: params.row,
            }}
          >
            Edit
          </Link>
        )
      },
      sortable: false,
      align: 'center',
    },
  ]

  useEffect(() => {
    setPods(data)
  }, [data, pods])

  const { sortModel, filterModel, columnModel } = useSelector(
    (state: RootState) => state.dataGrid
  )

  const onSortModelChange = (model: GridSortModel) => {
    dispatch(setSortModel(model))
  }

  const onFilterModelChange = (model: GridFilterModel) => {
    dispatch(setFilterModel(model))
  }
  const onColumnVisibilityChange = (model: GridColumnVisibilityModel) => {
    dispatch(setColumnModel(model))
  }

  return (
    <TableContainer component={Paper} sx={{ padding: '0', height: '100vh' }}>
      <DataGrid
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
              ...columnModel,
            },
          },
          // sorting: {
          //   sortModel: [{ field: 'startDateTime',
          //   sort: 'asc'
          // }],
          // ...sortModel
          // },
        }}
        onSortModelChange={onSortModelChange}
        onFilterModelChange={onFilterModelChange}
        onColumnVisibilityModelChange={onColumnVisibilityChange}
        sortModel={sortModel}
        filterModel={filterModel}
        rows={data}
        columns={columns}
      />
    </TableContainer>
  )
}

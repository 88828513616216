/* eslint-disable no-useless-escape */
import { PodApi, api } from '.'
import { LDAP } from '../react-app-env'
import { cleanInterviewer } from '../utils/cleanClasses'
import { scanLDap } from '../utils/dbQueries'

export interface UserIdentities {
  dateCreated: number
  providerName: string
  providerType: string
  userId: string
}

export function getPriorityLDAP(
  LDAPs: LDAP[],
  groups: string[]
): LDAP | undefined {
  const sortedPriorityLDaps = LDAPs.sort(
    ({ permissionPriority: a }, { permissionPriority: b }) => a - b
  )

  let priorityLDap = sortedPriorityLDaps[0]

  for (let i = 0; i < sortedPriorityLDaps.length; i++) {
    priorityLDap = sortedPriorityLDaps[i]
    const match = groups.find((group) => group === priorityLDap.group)
    if (match) {
      console.log(27, match, priorityLDap)
      return priorityLDap
    }
  }
  return undefined
}

async function setRole(
  api: PodApi,
  groups: string[],
  onRole: any,
  user: any
): Promise<[string, string]> {
  const roles = await scanLDap(api)
  const role = getPriorityLDAP(roles, groups)

  if (!role) {
    return ['', '']
  }
  if (typeof onRole === 'function') onRole(role.podRole, role.rcGroup, user)
  return [role.podRole, role.rcGroup]
}

async function getCompetencies(api: PodApi, alias: string): Promise<string[]> {
  const userResponse = await api.get('InterviewerByAlias', {
    alias: alias,
  })
  const tempUserInfo = cleanInterviewer(userResponse)
  if (tempUserInfo) {
    return tempUserInfo.competencies
  } else {
    return []
  }
}

export class PodUser {
  readonly id: string
  readonly alias: string
  readonly firstName: string
  readonly lastName: string
  readonly managerAlias: string
  readonly jobLevel: number
  readonly jobTitle: string
  readonly stage: string
  readonly provider: string
  readonly providerType: string
  readonly name: string
  readonly email: string
  readonly isBarRaiser: boolean
  readonly isManager: boolean
  identities: UserIdentities[]
  managerName: string
  private $whitelabel?: string
  private $role?: string
  private _competencies: string[]

  protected $groups: string[] = []

  constructor(
    cognitoUser: any,
    onRole?: (role: string, group: string, $this: PodUser) => void
  ) {
    const token = cognitoUser?.attributes
    if (!token) throw new Error('Cannot find user')
    try {
      this.identities = JSON.parse(token.identities)
    } catch (e) {
      this.identities = token.identities
    }
    let [provider, alias] = (cognitoUser.username || '').split('_')
    if (!alias) alias = token.identities ? token.identities[0]?.userId : ''
    if (!provider)
      provider = token.identities ? token.identities[0]?.providerName : ''
    this.id = token.sub
    this.alias = alias
    this.firstName = token.given_name || ''
    this.lastName = token.family_name || ''
    this.provider = provider || ''
    const [providerType, stage] = this.provider.split('-')
    this.providerType = providerType
    this.stage = (stage || '').toLowerCase()
    this.jobLevel = Number(token['custom:AMZN_JOB_CODE'])
    this.jobTitle = token['custom:DESCRIPTION']
    this.name = token.given_name + ' ' + token.family_name
    this.email = token.email || this.alias + '@amazon.com'
    this.isBarRaiser = this.parseBarRaiser(token['custom:AMZNBARRAISER'])
    this.isManager =
      token.hasOwnProperty('custom:AMZN_JOB_CODE') &&
      (token['custom:AMZN_JOB_CODE'] === 0 ||
        token['custom:AMZN_JOB_CODE'] === '0')
    const data = token['custom:MANAGER']
    const match = data.match(/cn\=([^\()]*)\(([^\)]*)\)/)
    this.managerName = (match[1] || '').trim()
    this.managerAlias = match[2]
    if (!this.jobLevel) {
      throw new Error('User has invalid job level')
    }
    try {
      this.$groups = JSON.parse(token['custom:LDAP'])
    } catch (e) {
      this.$groups = []
    }
    setRole(api, this.$groups, onRole, this)
      .then(([role, group]) => {
        this.$role = role
        this.$whitelabel = group
      })
      .catch((e) => {
        console.error(e)
        Promise.reject("Can't find User Role")
      })
    this._competencies = []
    getCompetencies(api, this.alias).then((competencies) => {
      this.competencies = competencies
    })
  }

  // alias of alias
  get username(): string {
    return this.alias
  }

  get role(): string {
    return this.$role || ''
  }

  get whitelabel(): string {
    return this.$whitelabel || ''
  }

  get mainGroup(): string {
    return this.groups[0]
  }

  get groups(): string[] {
    return Array.isArray(this.$groups) ? this.$groups : []
  }

  get level(): string {
    return 'L' + this.jobLevel
  }
  // alias of groups
  get ldapGroups(): string[] {
    return this.groups
  }
  // alias of this.id
  get sub(): string {
    return this.id
  }
  get badgePhoto(): string {
    return `https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${this.username}`
  }

  get competencies(): string[] {
    return this._competencies
  }

  set competencies(value: string[]) {
    this._competencies = value
  }

  getPhoneTool(alias?: string): string {
    return `https://phonetool.amazon.com/users/${alias || this.username}`
  }

  isIn(groupName): boolean {
    if (!groupName) return false
    if (!this.groups || !this.groups.length) return false
    const regex = new RegExp(groupName, 'i')
    return this.groups.find((group) => regex.test(group)) ? true : false
  }

  isAuthenticated(role?: string): boolean {
    if (!role) role = this.role
    return !!(role && !/(unauthenticated)/i.test(this.role))
  }

  isInterviewer(role?: string): boolean {
    if (!role) role = this.role
    return !!(role && !/(recruiter|unauthenticated)/i.test(this.role))
  }

  isRecruiter(role?: string): boolean {
    if (!role) role = this.role
    return !!(role && !/(interviewer|unauthenticated)/i.test(this.role))
  }

  private parseBarRaiser(barRaiser): boolean {
    if (typeof barRaiser === 'boolean') return barRaiser
    if (!barRaiser) return false
    if (typeof barRaiser === 'string') {
      return /^y/i.test(barRaiser.trim())
    }
    if (typeof barRaiser === 'number') return barRaiser ? true : false
    return false
  }
}

import {
  Alert,
  Badge,
  Box,
  Button,
  Grid,
  Link,
  Menu,
  MenuItem,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import DatePicker from '@mui/lab/DatePicker'
import * as React from 'react'
import { PodInput, Pod as RawPod } from '../../api/types'
import { Pod, PodDetails } from '../../react-app-env'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import TimePicker from '@mui/lab/TimePicker'
import {
  renderJobLevel,
  renderJobLevelDeletable,
} from '../../utils/displayUtils'
import { api } from '../../api'
import { useNavigate } from 'react-router'
import { cleanPod } from '../../utils/cleanClasses'

interface DetailProps {
  data: Pod
}

const EditPod = (podValues: PodDetails) => {
  return { podValues }
}

function modifyDay(time: Date, newDay: Date) {
  time.setFullYear(newDay.getFullYear(), newDay.getMonth(), newDay.getDate())
  return time
}

function modifyTimeInDay(day: Date, newTime: Date) {
  day.setHours(newTime.getHours(), newTime.getMinutes(), 0, 0)
  return day
}

const PodDetailer: React.FC<DetailProps> = ({ data }) => {
  const [state, dispatch] = React.useReducer(reducer, EditPod)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const openLevelDropDown = Boolean(anchorEl)
  const [podEdit, setPodEdit] = React.useState<PodDetails>({} as PodDetails)
  const [openSuccess, setOpenSuccess] = React.useState(false)
  const [openError, setOpenError] = React.useState(false)
  const [resave, setResave] = React.useState(false)

  const navigate = useNavigate()

  function reducer(state: any, action: any) {
    if (action.clear) {
      return []
    }
    const deepCopyState = { ...state }
    deepCopyState[action.type] = action.value
    return deepCopyState
  }

  async function fetchData() {
    const result = (await api.get('PodByPodId', { podId: data.id })) as RawPod
    const podDetails = cleanPod(result)
    if (podDetails) {
      setPodEdit(podDetails)
      if (resave) {
        setResave(false)
      }
    }
  }

  React.useEffect(() => {
    fetchData()
  }, [resave])

  const handleLevelDelete = (deleteLevel: number[] | number) => {
    if (podEdit.jobLevel.length >= 1) {
      const filteredLevel = podEdit.jobLevel?.filter(
        (level) => level !== deleteLevel
      )

      const modifyPod = { ...podEdit }
      modifyPod.jobLevel = filteredLevel
      setPodEdit(modifyPod)

      modifyStateLevels(filteredLevel)
    } else {
      console.log('need to have atleast one level')
    }
  }

  const handleLevelAdd = (level: number) => {
    const modifyPod = { ...podEdit }
    modifyPod.jobLevel.push(level)
    setPodEdit(modifyPod)

    modifyStateLevels(modifyPod.jobLevel)
  }

  function modifyStateLevels(jobLevels: number[]) {
    jobLevels?.sort()
    dispatch({ type: 'jobLevel', value: jobLevels })
  }

  async function handleSave() {
    const updatedValues = state
    let msgContents = ''
    Object.entries(updatedValues).forEach((entry) => {
      const key = entry[0]
      let value = entry[1]
      if (key.includes('Time')) {
        value = new Date(value as number).toLocaleString()
      }
      msgContents += key + ': ' + value + '\n'
    })

    const msg =
      'Pod: ' +
      data.id +
      ' will be updated with the following values: \n' +
      msgContents

    if (window.confirm(msg)) {
      const savePodInput: PodInput = {
        podTypeId: podEdit.podTypeId,
        rcGroup: podEdit.rcGroup,
        startTime: podEdit.startTime.getTime(),
        endTime: podEdit.endTime.getTime(),
        jobLevel: podEdit.jobLevel,
        contact: podEdit.contact,
      }
      Object.keys(updatedValues).forEach((key) => {
        savePodInput[key] = updatedValues[key]
      })

      api
        .put('Pod', {
          podId: data.id,
          input: savePodInput,
        })
        .then((e) => {
          setOpenSuccess(true)
          setResave(true)
        })
        .catch((err) => {
          setOpenError(true)
        })
    } else {
      setOpenError(true)
    }
    dispatch({
      clear: true,
    })
  }

  const handleDiscard = () => {
    navigate('/secure/recruiter')
  }

  const handleClose = () => {
    setOpenError(false)
    setOpenSuccess(false)
  }

  const handleLevelDropDownClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleLevelDropDownClose = () => {
    setAnchorEl(null)
  }

  const AddLevel = () => {
    const allLevels = [4, 5, 6]
    const levelToAdd = allLevels.filter((level) => {
      return !podEdit.jobLevel?.includes(level)
    })
    if (levelToAdd.length == 0) {
      return
    }

    const itemOptions = levelToAdd.map((level) => (
      <MenuItem
        key={level}
        value={level}
        onClick={() => {
          handleLevelDropDownClose()
          handleLevelAdd(level)
        }}
        disableRipple
      >
        {level}
      </MenuItem>
    ))
    return (
      <div>
        <Button
          id="demo-customized-button"
          aria-controls={openLevelDropDown ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openLevelDropDown ? 'true' : undefined}
          variant="contained"
          disableElevation
          onClick={handleLevelDropDownClick}
          endIcon={<AddIcon />}
        >
          Add Level
        </Button>
        <Menu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={openLevelDropDown}
          onClose={handleLevelDropDownClose}
        >
          {itemOptions}
        </Menu>
      </div>
    )
  }

  return (
    <>
      {podEdit ? (
        <>
          <Paper>
            <Box sx={{ padding: '20px' }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <h2
                    style={{
                      margin: 0,
                      padding: '0 0 10px 0',
                      borderBottom: '1px solid #ccc',
                    }}
                  >
                    POD Details
                  </h2>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="h6" component="div">
                    Date
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Badge
                      invisible={state['startTime'] == undefined}
                      color="success"
                      variant="dot"
                    >
                      <DatePicker
                        label=""
                        value={
                          state['startTime']
                            ? new Date(state['startTime'])
                            : new Date(podEdit.startTime)
                        }
                        onChange={(newValue) => {
                          if (newValue && newValue.getTime() > 0) {
                            dispatch({
                              type: 'startTime',
                              value: modifyDay(
                                podEdit.startTime,
                                newValue
                              ).getTime(),
                              original: podEdit.startTime,
                            })
                            dispatch({
                              type: 'endTime',
                              value: modifyDay(
                                podEdit.endTime,
                                newValue
                              ).getTime(),
                              original: podEdit.endTime,
                            })
                          }
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Badge>
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="h6" component="div">
                    Time
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Badge
                      invisible={state['startTime'] == undefined}
                      color="success"
                      variant="dot"
                    >
                      <TimePicker
                        label="From"
                        value={
                          state['startTime']
                            ? new Date(state['startTime'])
                            : podEdit.startTime
                        }
                        onChange={(newValue) => {
                          if (newValue && newValue.getTime() > 0) {
                            dispatch({
                              type: 'startTime',
                              value: modifyTimeInDay(
                                podEdit.startTime,
                                newValue
                              ).getTime(),
                              //original: podEdit.startTime,
                            })
                          }
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Badge>
                    &nbsp;&nbsp;&nbsp;
                    <Badge
                      invisible={state['endTime'] == undefined}
                      color="success"
                      variant="dot"
                    >
                      <TimePicker
                        label="To"
                        value={
                          state['endTime'] ? state['endTime'] : podEdit.endTime
                        }
                        onChange={(newValue) => {
                          if (newValue && newValue.getTime() > 0) {
                            dispatch({
                              type: 'endTime',
                              value: modifyTimeInDay(
                                podEdit.endTime,
                                newValue
                              ).getTime(),
                              //original: podEdit.endTime,
                            })
                          }
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Badge>
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="h6" component="div">
                    Type
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="h6" component="div">
                    {podEdit.podTypeId}
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="h6" component="div">
                    Level
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Badge
                    invisible={state['jobLevel'] == undefined}
                    color="success"
                    variant="dot"
                  >
                    {podEdit.jobLevel
                      ? podEdit.jobLevel.map((level) => {
                          if (podEdit.jobLevel.length > 1) {
                            return renderJobLevelDeletable(
                              level,
                              handleLevelDelete
                            )
                          } else {
                            return renderJobLevel(level)
                          }
                        })
                      : ''}
                  </Badge>
                  {AddLevel()}
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="h6" component="div">
                    RC
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="h6" component="div">
                    {podEdit.rcGroup}
                  </Typography>{' '}
                  <Link href={`mailTo:${podEdit.rcGroup}@amazon.com`}>
                    {podEdit.rcGroup}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Paper>

          {/* Prebrief Section */}
          {/** TODO: Uncoment if we need pre-breif and post-breif
          <Paper sx={{ marginTop: '20px' }}>
            <Box sx={{ padding: '20px' }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <h2
                    style={{
                      margin: 0,
                      padding: '0 0 10px 0',
                      borderBottom: '1px solid #ccc',
                    }}
                  >
                    Prebrief
                  </h2>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="h6" component="div">
                    Date
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Badge
                      invisible={state['preBriefStartTime'] == undefined}
                      color="success"
                      variant="dot"
                    >
                      <DatePicker
                        label=""
                        value={
                          state['preBriefStartTime']
                            ? state['preBriefStartTime']
                            : podEdit.preBriefStartTime
                            ? new Date(podEdit.preBriefStartTime)
                            : new Date(podEdit.startTime)
                        }
                        onChange={(newValue) => {
                          dispatch({
                            type: 'preBriefStartTime',
                            value: newValue?.getTime(),
                            original: podEdit.preBriefEndTime,
                          })
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Badge>
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="h6" component="div">
                    Time
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Badge
                      invisible={state['preBriefStartTime'] == undefined}
                      color="success"
                      variant="dot"
                    >
                      <TimePicker
                        label="From"
                        value={
                          state['preBriefStartTime']
                            ? state['preBriefStartTime']
                            : podEdit.preBriefStartTime
                            ? new Date(podEdit.preBriefStartTime)
                            : new Date(podEdit.startTime)
                        }
                        onChange={(newValue) => {
                          dispatch({
                            type: 'preBriefStartTime',
                            value: newValue?.getTime(),
                            original: podEdit.preBriefEndTime,
                          })
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Badge>
                    &nbsp;&nbsp;&nbsp;
                    <Badge
                      invisible={state['preBriefEndTime'] == undefined}
                      color="success"
                      variant="dot"
                    >
                      <TimePicker
                        label="To"
                        value={
                          state['preBriefEndTime']
                            ? state['preBriefEndTime']
                            : podEdit.preBriefEndTime
                            ? new Date(podEdit.preBriefEndTime)
                            : new Date(podEdit.endTime)
                        }
                        onChange={(newValue) => {
                          dispatch({
                            type: 'preBriefEndTime',
                            value: newValue?.getTime(),
                            original: podEdit.preBriefEndTime,
                          })
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Badge>
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="h6" component="div">
                    Location
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Badge
                    invisible={state['preBriefLocation'] == undefined}
                    color="success"
                    variant="dot"
                  >
                    <TextField
                      id="txtDebrief"
                      variant="outlined"
                      fullWidth={true}
                      defaultValue={podEdit.preBriefLocation || ''}
                      onChange={(newValue) => {
                        dispatch({
                          type: 'preBriefLocation',
                          value: newValue.target.value,
                        })
                      }}
                    />
                  </Badge>
                </Grid>
              </Grid>
            </Box>
          </Paper>

          {/* Debreif Section */}
          {/**
          <Paper sx={{ marginTop: '20px' }}>
            <Box sx={{ padding: '20px' }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <h2
                    style={{
                      margin: 0,
                      padding: '0 0 10px 0',
                      borderBottom: '1px solid #ccc',
                    }}
                  >
                    Debrief
                  </h2>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="h6" component="div">
                    Date
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Badge
                      invisible={state['postBriefStartTime'] == undefined}
                      color="success"
                      variant="dot"
                    >
                      <DatePicker
                        label=""
                        value={
                          state['postBriefStartTime']
                            ? state['postBriefStartTime']
                            : podEdit.postBriefStartTime
                            ? new Date(podEdit.postBriefStartTime)
                            : new Date(podEdit.startTime)
                        }
                        onChange={(newValue) => {
                          dispatch({
                            type: 'postBriefStartTime',
                            value: newValue?.getTime(),
                            original: podEdit.postBriefEndTime,
                          })
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Badge>
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="h6" component="div">
                    Time
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Badge
                      invisible={state['postBriefStartTime'] == undefined}
                      color="success"
                      variant="dot"
                    >
                      <TimePicker
                        label="From"
                        value={
                          state['postBriefStartTime']
                            ? state['postBriefStartTime']
                            : podEdit.postBriefStartTime
                            ? new Date(podEdit.postBriefStartTime)
                            : new Date(podEdit.startTime)
                        }
                        onChange={(newValue) => {
                          dispatch({
                            type: 'postBriefStartTime',
                            value: newValue?.getTime(),
                            original: podEdit.postBriefEndTime,
                          })
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Badge>
                    &nbsp;&nbsp;&nbsp;
                    <Badge
                      invisible={state['postBriefEndTime'] == undefined}
                      color="success"
                      variant="dot"
                    >
                      <TimePicker
                        label="To"
                        value={
                          state['postBriefEndTime']
                            ? state['postBriefEndTime']
                            : podEdit.postBriefEndTime
                            ? new Date(podEdit.postBriefEndTime)
                            : new Date(podEdit.endTime)
                        }
                        onChange={(newValue) => {
                          dispatch({
                            type: 'postBriefEndTime',
                            value: newValue?.getTime(),
                            original: podEdit.postBriefEndTime,
                          })
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Badge>
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="h6" component="div">
                    Location
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Badge
                    invisible={state['postBriefLocation'] == undefined}
                    color="success"
                    variant="dot"
                  >
                    <TextField
                      id="txtDebrief"
                      variant="outlined"
                      fullWidth={true}
                      defaultValue={podEdit.postBriefLocation || ''}
                      onChange={(newValue) => {
                        dispatch({
                          type: 'postBriefLocation',
                          value: newValue.target.value,
                        })
                      }}
                    />
                  </Badge>
                </Grid>
              </Grid>
            </Box>
          </Paper>
                    **/}
        </>
      ) : (
        <></>
      )}
      <Paper
        sx={{
          marginTop: '20px',
          display: 'inline-flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Button
          onClick={handleDiscard}
          style={{ fontWeight: 'bold', padding: '1.25em', fontSize: '1em' }}
        >
          {' '}
          Discard
        </Button>
        <Button
          onClick={handleSave}
          style={{ fontWeight: 'bold', padding: '1.25em', fontSize: '1em' }}
          disabled={Object.keys(state).length == 0}
        >
          {' '}
          Save
        </Button>
      </Paper>
      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert severity="success">DB Saved succesfully!</Alert>
      </Snackbar>
      <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose}>
        <Alert severity="error">Failed to update DB.</Alert>
      </Snackbar>
    </>
  )
}

export default PodDetailer

import * as React from 'react'
import { Link } from 'react-router-dom'
const NotFoundDefault: React.FC = () => {
  return (
    <div className="not-found">
      <span>
        Sorry the page your are looking for was not found.{' '}
        <Link to="/">Click Here</Link> to return to the main location.
      </span>
    </div>
  )
}

const NotFound: React.FC<{ message?: string }> = ({ message }) => {
  return message ? (
    <div className="not-found">
      <h3>{message}</h3>
    </div>
  ) : (
    <NotFoundDefault />
  )
}

export default NotFound

import { Divider, Typography } from '@mui/material'
import * as React from 'react'

const Public: React.FC = () => {
  return (
    <div>
      <h2>Public Page</h2>
      <Divider />
      <br />
      <Typography variant="body1">
        You're seeing this page because you have either logged off or do not
        have sufficient ldap privileges to be an interviewer or recruiter
      </Typography>
    </div>
  )
}

export default Public

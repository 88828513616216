import { MutableRefObject, useRef } from 'react'

// none is a special value used to detect an uninitialized ref
export function useLazyRef<T>(init: () => T): MutableRefObject<T> {
  // not initialized yet
  const ref = useRef<T>()
  // if it's not initialized (1st render)
  if (ref.current === undefined) {
    // we initialize it
    ref.current = init() as T
  }
  // new we return the initialized ref
  return ref as MutableRefObject<T>
}

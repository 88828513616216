import * as React from 'react'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import SendIcon from '@mui/icons-material/Send'
import UndoIcon from '@mui/icons-material/Undo'
import { PromisePool } from '@supercharge/promise-pool'
import { v4 as uuidv4 } from 'uuid'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

import {
  CandidateInput,
  InterviewType,
  ScheduleInput,
  ScheduleStatus,
  SendEmailInput,
} from '../../api/types'
import { PodApi } from '../../api'
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux'
import { InterviewerSlot } from './InterviewSlot'
import {
  addCandidateList,
  removeCandidate,
  updateCandidate,
  updateCandidateHireLink,
  updateCandidateSchedule,
  updateScheduleStatus,
} from '../../states/podSlice'
import { Candidate, Pod, PodType, Schedule } from '../../react-app-env'
import { useLazyRef } from '../../utils/useLazyRef'
import {
  cleanInterviewer,
  cleanSchedule,
  getInterviewType,
} from '../../utils/cleanClasses'

import {
  getScheduleOfInterviewType,
  makeDefaultSchedules,
} from '../../utils/ScheduleUtils'
import {} from 'notistack'
import { Variant, feedbackSnackbar } from '../../utils/feedbackSnackbar'
import { renderWithSpacing } from '../../utils/displayUtils'
import {
  MapCandidateOrder,
  getRotateTimeSlotCount,
} from '../../utils/ScheduleSlotTimeCalculator'
import store from '../../store'

interface CandidateProps {
  id: string
}

type ChangeTracker = {
  candidateId: string // Remove?
  interviewType: InterviewType
  newSchedule: Schedule
  oldSchedule: Schedule
}

type ChangesMap = {
  [key: string]: ChangeTracker
}

function makeKey<T extends string, U extends InterviewType>(
  candidateId: T,
  interviewType: U
): `${T}~${U}` {
  return `${candidateId}~${interviewType}`
}

function revertInterviewer(
  dispatch: any,
  podId: string,
  candidateId: string,
  changes: ChangeTracker
) {
  dispatch(
    updateCandidateSchedule({
      podId: podId,
      candidateId: candidateId,
      schedule: changes.oldSchedule,
    })
  )
}

function removeChangeFromChangeMap(
  key: string,
  modifiedInterviewers: ChangesMap
): ChangesMap {
  let newList = { ...modifiedInterviewers }
  const { [key]: removedChange, ...rest } = newList
  newList = rest
  return newList
}

function createCandidates(
  count,
  level: number,
  position: string,
  pod: Pod,
  podTypes: PodType[],
  orderedTypes: InterviewType[]
): Candidate[] {
  const mappedOrder = MapCandidateOrder(pod.candidates, orderedTypes)

  const candidatesList: Candidate[] = []
  for (let i = 0; i < count; i++) {
    const indexInPod = getRotateTimeSlotCount(mappedOrder, i)
    if (indexInPod == null || indexInPod < 0) {
      console.error(
        'failed to determine timeslots for new candidate',
        indexInPod
      )
      feedbackSnackbar('Failed to create new candidate', Variant.ERROR)
      break
    }

    const candidateId = uuidv4()
    const candidate: Candidate = {
      candidateId: candidateId,
      hireLink: '',
      level: +level,
      name: '',
      position: pod.type,
      schedules: makeDefaultSchedules(pod, candidateId, indexInPod),
    }

    candidatesList.push(candidate)
  }

  return candidatesList
}

async function saveNewCandidatesAndSchedulesToDB(
  api: PodApi,
  podId: string,
  candidates: Candidate[]
) {
  candidates.forEach((candidate) => {
    const candidateInput: CandidateInput = {
      podId: podId,
      jobLevel: candidate.level,
      jobTitle: candidate.position,
      name: candidate.name,
      hireLink: null,
      hireId: null,
    }
    api.put('Candidate', {
      candidateId: candidate.candidateId,
      input: candidateInput,
    })
    candidate.schedules.forEach((schedule) => {
      saveSchedule(api, schedule).catch((err) => {
        console.warn(err)
      })
    })
  })
}

async function saveSchedule(api: PodApi, schedule: Schedule) {
  const input: ScheduleInput = {
    endTime: schedule.endTime.getTime(),
    interviewer: schedule.interviewer,
    podId: schedule.podId,
    queue: JSON.stringify(schedule.queue),
    startTime: schedule.startTime.getTime(),
    status: schedule.scheduleStatus,
  }
  try {
    // push change to db
    await api.put('Schedule', {
      candidateId: schedule.candidateId,
      input: input,
      interviewType: schedule.interviewType.valueOf(),
    })
  } catch (error) {
    console.warn('failed to modify schedule', error)
  }
}

async function saveAllScheduleSlotsForCandidate(
  api: PodApi,
  pod: Pod,
  candidateId: string,
  modifiedSchedules: ChangesMap
): Promise<[any, string[]]> {
  let newList = { ...modifiedSchedules }
  const errors: string[] = []

  await PromisePool.withConcurrency(5)
    .for(Object.keys(InterviewType))
    .process(async (interviewType) => {
      const key = makeKey(candidateId, getInterviewType(interviewType))

      if (newList[key]) {
        saveSchedule(api, newList[key].newSchedule).catch((error) => {
          errors.push(error)
        })
        newList = removeChangeFromChangeMap(key, newList)
      }
    })
  return [newList, errors]
}

function revertAllInterviewersForCandidate(
  dispatch: any,
  podId: string,
  candidateId: string,
  modifiedInterviewers: ChangesMap
) {
  let newList = { ...modifiedInterviewers }

  Object.keys(InterviewType).forEach((interviewType) => {
    const key = makeKey(candidateId, getInterviewType(interviewType))

    if (newList[key]) {
      revertInterviewer(dispatch, podId, candidateId, newList[key])

      newList = removeChangeFromChangeMap(key, newList)
    }
  })
  return newList
}

function AllInterviewSlotsAssigned(
  candidates: Candidate[],
  interviewType: InterviewType
) {
  let emptyFound = false
  candidates.forEach((candidate) => {
    const schedule = getScheduleOfInterviewType(
      candidate.schedules,
      interviewType
    )
    if (schedule && schedule.interviewer) {
      if (schedule.interviewer == '<empty>') {
        emptyFound = true
      }
    } else {
      emptyFound = true
    }
  })

  return !emptyFound
}

function NoPendingChangesForInterviewerSlots(
  changes: ChangesMap,
  candidates: Candidate[],
  interviewType: InterviewType
) {
  let changesNotSaved = false
  candidates.forEach((candidate) => {
    const key = makeKey(candidate.candidateId, interviewType)
    if (changes[key]) {
      changesNotSaved = true
    }
  })
  return !changesNotSaved
}

async function updateStatusOfSchedule(
  api: PodApi,
  candidateId: string,
  interviewType: InterviewType | InterviewType,
  newStatus: ScheduleStatus
) {
  const schedule = cleanSchedule(
    await api.get('getScheduleByCandidateIdAndInterviewType', {
      candidateId: candidateId,
      interviewType: interviewType,
    })
  )

  if (!schedule) {
    const message = 'can not find schedule to update status'
    console.error(message)
    feedbackSnackbar(message, Variant.ERROR)
  }

  const input: ScheduleInput = {
    endTime: schedule?.endTime.getTime(),
    interviewer: schedule?.interviewer,
    podId: schedule?.podId,
    queue: JSON.stringify(schedule?.queue),
    startTime: schedule?.startTime.getTime(),
    status: newStatus,
  }

  api
    .put('Schedule', {
      candidateId: candidateId,
      interviewType: interviewType,
      input: input,
    })
    .catch((e) => {
      console.error(e)
      feedbackSnackbar(
        'Failed to update schedule with status update',
        Variant.ERROR
      )
    })
}

async function sendConfirmEmailToInterviewer(
  api: PodApi,
  podId: string,
  podType: string,
  schedule: Schedule
): Promise<Schedule | null> {
  const result = await api.get('getInterviewerByAlias', {
    alias: schedule.interviewer,
  })
  const interviewer = cleanInterviewer(result)
  if (interviewer) {
    const scheduleInput: ScheduleInput = {
      endTime: schedule.endTime.getTime(),
      interviewer: interviewer.alias,
      podId: podId,
      queue: JSON.stringify(schedule.queue),
      startTime: schedule.startTime.getTime(),
    }
    const temp: SendEmailInput = {
      alias: interviewer.alias,
      email: interviewer.email,
      input: scheduleInput,
      podType: podType,
    }

    try {
      await api.send('sendEmailOnConfirmed', { input: temp })
      updateStatusOfSchedule(
        api,
        schedule.candidateId,
        schedule.interviewType,
        ScheduleStatus.Emailed
      )
      feedbackSnackbar('Email confirmation sent', Variant.SUCCESS, true)
      return schedule
    } catch (error) {
      console.error('An error ocurred when Sending EmailConfirm', error)
      console.error(error)
      feedbackSnackbar('An error occurred while sending email', Variant.ERROR)
    }
    return null
  } else {
    const warning = `Interviewer ${schedule.interviewer} was not found in the DataBase`
    console.warn(warning)
    feedbackSnackbar(warning, Variant.WARNING, true)
    return null
  }
}

async function ScheduleInterviewSlotsForInterviewType(
  api: PodApi,
  pod: Pod,
  interviewType: InterviewType,
  dispatch: any
) {
  const schedules = pod.candidates.map((candidate) => {
    return getScheduleOfInterviewType(candidate.schedules, interviewType)
  })

  const { results: schedulesThatSentConfirmEmail } = await PromisePool.for(
    schedules
  ).process((schedule) => {
    if (schedule) {
      return sendConfirmEmailToInterviewer(api, pod.id, pod.type, schedule)
    }
    return null
  })

  const { errors: updateReduxErrors } = await PromisePool.for(
    schedulesThatSentConfirmEmail
  ).process((schedule) => {
    if (schedule) {
      dispatch(
        updateScheduleStatus({
          candidateId: schedule.candidateId,
          interviewType: schedule.interviewType,
          newStatus: ScheduleStatus.Emailed,
          podId: schedule.podId,
        })
      )
    }
  })
  if (updateReduxErrors.length > 0) {
    updateReduxErrors.forEach((error) => {
      console.error(error)
    })
  }
}

async function deleteScheduleInDB(
  api: PodApi,
  candidateId: string,
  interviewType: InterviewType
) {
  api.delete('Schedule', {
    candidateId: candidateId,
    interviewType: interviewType,
  })
}

async function notifyInterviewerOfCancel(
  api: PodApi,
  schedule: Schedule,
  podType: string
) {
  const interviewer = cleanInterviewer(
    await api.get('InterviewerByAlias', { alias: schedule.interviewer })
  )
  if (!interviewer) {
    console.error('Interviewer not found in db', schedule.interviewer)
    feedbackSnackbar(`Failed to email ${schedule.interviewer}`, Variant.ERROR)
    return false
  }

  const scheduleInput: ScheduleInput = {
    endTime: schedule.endTime.getTime(),
    interviewer: interviewer.name,
    podId: schedule.podId,
    queue: JSON.stringify(schedule.queue),
    startTime: schedule.startTime.getTime(),
  }

  const input: SendEmailInput = {
    alias: interviewer.alias,
    email: interviewer.email,
    input: scheduleInput,
    podType: podType,
  }
  await api.send('sendEmailOnCancel', { input: input }).catch((error) => {
    console.error(error)
    feedbackSnackbar(
      `Cancelation email failed to send for ${interviewer.name}`,
      Variant.ERROR,
      true
    )
    return false
  })
  feedbackSnackbar(
    `Cancelation email sent to ${interviewer.name}`,
    Variant.SUCCESS,
    true
  )
  return true
}

async function deleteSchedulesOfCandidateInDB(
  api: PodApi,
  candidate: Candidate
) {
  let succeed = true
  candidate.schedules.forEach((schedule) => {
    if (schedule.scheduleStatus == ScheduleStatus.Emailed) {
      notifyInterviewerOfCancel(api, schedule, candidate.position)
    }
    deleteScheduleInDB(
      api,
      candidate.candidateId,
      schedule.interviewType
    ).catch((e) => {
      console.log(e)
      feedbackSnackbar(
        'Error occurred while removing schedules from DB',
        Variant.ERROR
      )
      succeed = false
    })
  })
  return succeed
}

async function deleteCandidateInDB(api: PodApi, candidate: Candidate) {
  await api
    .delete('Candidate', { candidateId: candidate.candidateId })
    .catch((e) => {
      console.error(e),
        feedbackSnackbar('Failed to delete candidate from DB', Variant.ERROR)
      return false
    })
  return true
}

function RemoveCandidateFromState(
  dispatch: any,
  podId: string,
  candidateId: string
) {
  try {
    dispatch(removeCandidate({ podId: podId, candidateId: candidateId }))
  } catch (e) {
    feedbackSnackbar('Error occurred while updating state', Variant.ERROR)
    console.error(e)
    return false
  }
  return true
}

const CandidateList: React.FC<CandidateProps> = ({ id }) => {
  const [level, setLevel] = React.useState<string | number>('')
  const [position, setPosition] = React.useState('')
  const [count, setCount] = React.useState(1)
  const pods = useAppSelector((state) => state.pods)
  const pod = useAppSelector(
    (state) => state.pods.filter((x) => x.id === id)[0]
  )
  const [displayCandidateDeleteDialog, setDisplayCandidateDeleteDialog] =
    React.useState(false)
  const [candidateIdMarkedForDelete, setCandidateIdToDelete] = React.useState<
    string | null
  >(null)
  const interviewerTypes: InterviewType[] = [
    InterviewType.HiringManager,
    InterviewType.BarRaiser,
    InterviewType.TechDepth,
    InterviewType.TechBreadth,
    InterviewType.NonProserve,
  ]

  const [modifiedInterviewers, setModifiedInterviewers] =
    React.useState<ChangesMap>({})

  function addModifiedSchedule(
    candidateId: string,
    interviewType: InterviewType,
    newSchedule: Schedule,
    oldSchedule: Schedule
  ) {
    const key = makeKey(candidateId, interviewType)
    const newList: ChangesMap = { ...modifiedInterviewers }
    const _oldSchedule = newList[key] ? newList[key].oldSchedule : oldSchedule

    newList[key] = {
      newSchedule: newSchedule,
      oldSchedule: _oldSchedule,
      candidateId: candidateId,
      interviewType: interviewType,
    }
    if (newSchedule.interviewer === _oldSchedule.interviewer) {
      delete newList[key]
    }

    setModifiedInterviewers(newList)
  }

  //const api = new PodApi()
  const apiRef = useLazyRef(() => new PodApi())

  const dispatch = useAppDispatch()

  const handleLevelChange = (event: SelectChangeEvent) => {
    setLevel(+event.target.value)
  }

  const handlePositionChange = (event: SelectChangeEvent) => {
    setPosition(event.target.value as string)
  }

  const handleCountChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const num: number = parseInt(event.target.value)
    if (num < 1) {
      setCount(1)
    } else {
      setCount(num)
    }
  }

  async function handleAddCandidates() {
    const podTypes = store.getState().podTypes

    const candidateList = createCandidates(
      count,
      +level,
      position,
      pod,
      podTypes,
      interviewerTypes
    )

    try {
      saveNewCandidatesAndSchedulesToDB(apiRef.current, pod.id, candidateList)
      dispatch(
        addCandidateList({
          podId: pod.id,
          candidates: candidateList,
        })
      )

      reset()
      feedbackSnackbar('Added candidate(s) successfully', Variant.SUCCESS)
    } catch (error) {
      console.error(error)
      feedbackSnackbar(
        'Error occurred while creating Candidate(s)',
        Variant.ERROR
      )
    }
  }

  const reset = () => {
    setLevel('')
    setPosition('')
    setCount(1)
  }

  async function handleAcceptAll(interviewType: InterviewType) {
    await ScheduleInterviewSlotsForInterviewType(
      apiRef.current,
      pod,
      interviewType,
      dispatch
    )
  }

  const handleEditCandidate = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string,
    defaultName: string
  ) => {
    const newName = prompt('Please Enter a new candidate name.', defaultName)
    if (newName) {
      dispatch(
        updateCandidate({
          id: pod.id,
          candidateId: id,
          newName,
        })
      )
      try {
        saveNewCandidateNameToDB(id, newName)
        feedbackSnackbar(
          `Candidate name updated to ${newName}`,
          Variant.SUCCESS
        )
      } catch (error) {
        feedbackSnackbar(
          'Candidate name failed to save to database',
          Variant.ERROR
        )
      }
    }
  }

  const verifyLink = (link: string) => {
    const httpRegex = new RegExp('^(http|https)://', 'i')
    let modifiedLink = link
    if (!httpRegex.test(link)) {
      modifiedLink = 'https://' + modifiedLink
    }
    return modifiedLink
  }

  const handleEditHireLink = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string,
    defaultLink: string
  ) => {
    const newLink = prompt('Please Enter a new HireLink.', defaultLink)
    if (newLink) {
      const hireLink = verifyLink(newLink)
      dispatch(
        updateCandidateHireLink({
          id: pod.id,
          candidateId: id,
          hireLink: hireLink,
        })
      )
      saveNewCandidateHireLinkToDB(id, hireLink)
    }
  }
  function saveNewCandidateNameToDB(candidateId: string, name: string | null) {
    const candidate = pod.candidates.find(
      (candidate) => candidate.candidateId === candidateId
    )
    if (candidate) {
      apiRef.current.put('Candidate', {
        candidateId: candidate.candidateId,
        input: {
          podId: pod.id,
          jobLevel: candidate.level,
          jobTitle: candidate.position,
          name: name,
          hireLink: null,
          hireId: null,
        },
      })
    }
  }

  function saveNewCandidateHireLinkToDB(
    candidateId: string,
    hireLink: string | null
  ) {
    const candidate = pod.candidates.find(
      (candidate) => candidate.candidateId === candidateId
    )
    if (candidate) {
      apiRef.current
        .put('Candidate', {
          candidateId: candidate.candidateId,
          input: {
            podId: pod.id,
            jobLevel: candidate.level,
            jobTitle: candidate.position,
            name: candidate.name,
            hireLink: hireLink,
            hireId: null,
          },
        })
        .catch((err) => {
          console.warn(err)
        })
    }
  }

  const handleEditAlias = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    oldSchedule: Schedule
  ) => {
    const newAlias = prompt('Please Enter a new Alias.')
    if (newAlias) {
      const newSchedule = { ...oldSchedule }
      newSchedule.interviewer = newAlias
      newSchedule.scheduleStatus = ScheduleStatus.Created

      try {
        dispatch(
          updateCandidateSchedule({
            podId: newSchedule.podId,
            candidateId: newSchedule.candidateId,
            schedule: newSchedule,
          })
        )
      } catch (error) {
        console.warn('an error occurred with dispatcher', error)
      }
      addModifiedSchedule(
        newSchedule.candidateId,
        getInterviewType(oldSchedule.interviewType),
        newSchedule,
        oldSchedule
      )
    }
  }

  const handleInterviewerInputChange = (
    candidateId: string,
    interviewType: InterviewType,
    newSchedule: Schedule,
    oldSchedule: Schedule
  ) => {
    dispatch(
      updateCandidateSchedule({
        podId: pod.id,
        candidateId: candidateId,
        schedule: newSchedule,
      })
    )
    addModifiedSchedule(candidateId, interviewType, newSchedule, oldSchedule)
  }

  const handleRevertInterviewerChangesForCandidate = (candidateId: string) => {
    const newChangeMap = revertAllInterviewersForCandidate(
      dispatch,
      pod.id,
      candidateId,
      modifiedInterviewers
    )
    setModifiedInterviewers(newChangeMap)
    feedbackSnackbar('Reverted all changes for candidate', Variant.SUCCESS)
  }

  async function handleSaveInterviewerChangesForCandidate(CandidateId: string) {
    const [newChangeMap, errors] = await saveAllScheduleSlotsForCandidate(
      apiRef.current,
      pod,
      CandidateId,
      modifiedInterviewers
    )

    if (errors.length === 0) {
      feedbackSnackbar('Saved changes to database', Variant.SUCCESS)
      setModifiedInterviewers(newChangeMap)
    } else {
      feedbackSnackbar('Failed to save interviewer details', Variant.SUCCESS)
      errors.forEach((error) => {
        console.error(error)
      })
    }
  }

  React.useEffect(() => {}, [pods, pod])

  function candidateInterviewersModified(candidateId: string): boolean {
    let found = false
    Object.keys(InterviewType).forEach((interviewType) => {
      const key = makeKey(candidateId, getInterviewType(interviewType))
      if (modifiedInterviewers[key]) {
        found = true
      }
    })
    return found
  }

  const SaveUndoCell = (candidateId: string) => {
    if (candidateInterviewersModified(candidateId)) {
      return (
        <TableCell>
          <Tooltip title="undo changes">
            <IconButton
              size="small"
              onClick={(e) =>
                handleRevertInterviewerChangesForCandidate(candidateId)
              }
            >
              <UndoIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
          <Tooltip title="save changes">
            <IconButton
              size="small"
              onClick={(e) =>
                handleSaveInterviewerChangesForCandidate(candidateId)
              }
            >
              <SaveIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </TableCell>
      )
    } else {
      return <TableCell />
    }
  }

  function CandidateTableHeader(interviewerTypes: InterviewType[]) {
    const headers = interviewerTypes.map((interviewType) => {
      const ready = AllInterviewSlotsAssigned(pod.candidates, interviewType)
      const noChanges = NoPendingChangesForInterviewerSlots(
        modifiedInterviewers,
        pod.candidates,
        interviewType
      )
      let tooltip
      if (ready && noChanges) {
        tooltip = `Schedule interviewers for ${renderWithSpacing(
          interviewType
        )}`
      } else if (!ready) {
        tooltip = `Please assign interviewers for ${renderWithSpacing(
          interviewType
        )}`
      } else if (!noChanges) {
        tooltip = `Please save all changes for  ${renderWithSpacing(
          interviewType
        )}`
      }

      const node = (
        <TableCell key={interviewType} align="center">
          {renderWithSpacing(interviewType)}
          <br />
          <Tooltip title={tooltip}>
            <span>
              <IconButton
                size="small"
                onClick={() => handleAcceptAll(interviewType)}
                disabled={!ready || !noChanges}
              >
                <SendIcon fontSize="inherit" />
              </IconButton>
            </span>
          </Tooltip>
        </TableCell>
      )
      return node
    })
    return (
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          {headers}
        </TableRow>
      </TableHead>
    )
  }

  function CandidateRows(
    candidate: Candidate,
    idx: number,
    interviewTypes: InterviewType[]
  ) {
    const interviewers = interviewTypes.map((interviewType) => {
      const node = (
        <TableCell
          align="center"
          key={makeKey(candidate.candidateId, interviewType)}
        >
          <InterviewerSlot
            schedule={getScheduleOfInterviewType(
              candidate.schedules,
              interviewType
            )}
            handleEditAlias={handleEditAlias}
            handleInterviewerInputChange={handleInterviewerInputChange}
          />
        </TableCell>
      )
      return node
    })

    return (
      <TableRow
        key={idx}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          {candidate.name ? candidate.name : `Candidate ${idx + 1}`}{' '}
          <IconButton
            size="small"
            onClick={(e) =>
              handleEditCandidate(
                e,
                candidate.candidateId,
                candidate.name ? candidate.name : `Candidate ${idx + 1}`
              )
            }
          >
            <EditIcon fontSize="inherit" />
          </IconButton>
          <IconButton
            size="small"
            onClick={(e) => {
              setDisplayCandidateDeleteDialog(true)
              setCandidateIdToDelete(candidate.candidateId)
            }}
          >
            <DeleteForeverIcon fontSize="inherit" />
          </IconButton>
          <br />
          <small>
            {candidate.level} - {candidate.position}
          </small>
          <br />
          <a href={candidate.hireLink ? candidate.hireLink : 'hireLink'}>
            {candidate.hireLink ? candidate.hireLink : 'hireLink'}
          </a>
          <IconButton
            size="small"
            onClick={(e) =>
              handleEditHireLink(
                e,
                candidate.candidateId,
                candidate.hireLink ? candidate.hireLink : 'hireLink'
              )
            }
          >
            <EditIcon fontSize="inherit" />
          </IconButton>
          <br />
        </TableCell>
        {interviewers}
        {SaveUndoCell(candidate.candidateId)}
      </TableRow>
    )
  }

  const LevelDropDown = (pod: Pod) => {
    const levelOptions = pod.jobLevel.map((level) => {
      return (
        <MenuItem key={level} value={level}>
          {level}
        </MenuItem>
      )
    })
    return (
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={level.toString()}
        type="number"
        onChange={handleLevelChange}
        size="small"
        label="Level"
      >
        {levelOptions}
      </Select>
    )
  }

  const ConfirmDeletePod = () => {
    if (!candidateIdMarkedForDelete) return <div />
    const candidate = pod.candidates.find(
      (candidate) => candidate.candidateId === candidateIdMarkedForDelete
    )
    if (!candidate) return <div />

    const displayName = candidate.name.length > 0 ? candidate.name : 'candidate'

    return (
      <div>
        <Dialog
          open={displayCandidateDeleteDialog}
          onClose={clearCandidateMarkedForDelete}
          aria-labelledby="Delete Candidate?"
          aria-describedby="Delete candidate confirmation"
        >
          <DialogTitle id="alert-dialog-title">
            {'Remove Candidate'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you wish to delete {displayName}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={clearCandidateMarkedForDelete} autoFocus>
              Cancel
            </Button>
            <Button
              onClick={(e) => {
                handleDeleteCandidateFromPod(candidate, pod)
              }}
            >
              Delete Candidate
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  function clearCandidateMarkedForDelete() {
    setCandidateIdToDelete(null)
    setDisplayCandidateDeleteDialog(false)
  }

  async function handleDeleteCandidateFromPod(candidate: Candidate, pod: Pod) {
    const succeed1 = await deleteSchedulesOfCandidateInDB(
      apiRef.current,
      candidate
    )
    const succeed2 = await deleteCandidateInDB(apiRef.current, candidate)
    const succeed3 = await RemoveCandidateFromState(
      dispatch,
      pod.id,
      candidate.candidateId
    )

    if (succeed1 && succeed2 && succeed3) {
      feedbackSnackbar('Successfully removed candidate', Variant.SUCCESS)
    } else {
      feedbackSnackbar('Error occurred while removing candidate', Variant.ERROR)
    }
    setCandidateIdToDelete(null)
    setDisplayCandidateDeleteDialog(false)
  }

  return (
    <>
      {ConfirmDeletePod()}
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', justifyContent: 'normal' }}
          rowSpacing={2}
        >
          <Grid
            item
            xs={8}
            sm={8}
            sx={{ display: 'flex', justifyContent: 'left' }}
          >
            <Box
              sx={{
                border: '1px solid #999',
                display: 'flex',
                padding: '20px',
                borderRadius: '10px',
              }}
            >
              <Box component="span" fontWeight="bold">
                Pod Time:&nbsp;{' '}
              </Box>
              {pod.startDateTime.toDateString().toLocaleString()}{' '}
              {pod.startDateTime.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
              })}{' '}
              -{' '}
              {pod.endDateTime.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
              })}
            </Box>
          </Grid>

          <Box
            sx={{
              border: '1px solid #999',
              display: 'flex',
              padding: '10px',
              borderRadius: '10px',
            }}
          >
            <Box sx={{ width: 150, marginLeft: '10px' }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Level</InputLabel>
                {LevelDropDown(pod)}
              </FormControl>
            </Box>

            <Box sx={{ marginLeft: '10px', width: 100 }}>
              <TextField
                variant="outlined"
                size="small"
                onChange={handleCountChange}
                value={count}
                type={'number'}
              />
            </Box>
            <Button
              size="small"
              variant="contained"
              disabled={!level}
              onClick={handleAddCandidates}
              sx={{ marginLeft: '10px' }}
            >
              Add Candidates
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              {CandidateTableHeader(interviewerTypes)}
              <TableBody>
                {pod.candidates.map((candidate, idx) =>
                  CandidateRows(candidate, idx, interviewerTypes)
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  )
}

export default CandidateList

import type { RouteObject } from 'react-router-dom'
import { Navigate } from 'react-router-dom'
import Layout from './components/Layout'
import Home from './views/Home'
import Profile from './views/Profile'
import PodScheduler from './views/PodScheduler'
import PrivateRoute from './privateRouter'
import FederatedSignIn from './components/FederatedSignIn'
import Public from './views/Public'
import NotFound from './views/NotFound'
import AdminDashboard from './views/AdminDashboard'
import { InterviewerDetails } from './views/InterviewerDetails'
import { RecruiterDashboard } from './views/RecruiterDashboard'
import PodDetails from './views/PodDetails'

// Enum to keep things consistent and programmatic across the app
export enum SitePaths {
  Root = '/',
  Login = '/login',
  Public = '/public',
  Secure = '/secure',
  Profile = '/profile',
  Interviewer = '/secure/interviewer',
  Recruiter = '/secure/recruiter',
  Pod = '/secure/recruiter/pod/:id',
  Admin = `/secure/admin`,
  InterviewerEdit = `/secure/admin/interviewer/:id`,
  CatchAll = '*',
}

export const appRoutes: RouteObject[] = [
  {
    element: <Layout />,
    children: [
      // Routes outside of security control.
      // The public path should be used for 1.) Users are not in the
      // allowed ldap groups for the app or 2.) when a user decides to logout.
      {
        path: SitePaths.Root,
        children: [
          { path: SitePaths.Login, element: <FederatedSignIn /> },
          { path: SitePaths.Public, element: <Public /> },
          { path: SitePaths.Profile, element: <Profile /> },
          { path: SitePaths.Root, element: <Navigate to={SitePaths.Secure} /> },
        ],
      },

      // This is were the bulk of the application lives.
      // Everything inside will be behind security via PrivateRoute component
      {
        path: SitePaths.Secure,
        element: <PrivateRoute />,
        children: [
          { index: true, element: <Home /> },
          {
            path: 'interviewer',
            children: [{ index: true, element: <PodScheduler /> }],
          },
          {
            path: 'recruiter',
            children: [{ index: true, element: <RecruiterDashboard /> }],
          },
          {
            path: 'admin',
            children: [{ index: true, element: <AdminDashboard /> }],
          },
          {
            path: SitePaths.Pod,
            children: [{ index: true, element: <PodDetails /> }],
          },
          {
            path: SitePaths.InterviewerEdit,
            children: [{ index: true, element: <InterviewerDetails /> }],
          },
        ],
      },

      // Catch any undefined routes
      { path: SitePaths.CatchAll, element: <NotFound /> },
    ],
  },
]

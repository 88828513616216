/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCandidateByCandidateId = /* GraphQL */ `
  query GetCandidateByCandidateId($candidateId: ID!) {
    getCandidateByCandidateId(candidateId: $candidateId) {
      candidateId
      hireId
      hireLink
      jobLevel
      jobTitle
      name
      podId
    }
  }
`;
export const getInterviewerByAlias = /* GraphQL */ `
  query GetInterviewerByAlias($alias: String!) {
    getInterviewerByAlias(alias: $alias) {
      alias
      competencies
      email
      isBr
      isMgr
      isTech
      jobLevel
      jobTitle
      lastPod
      name
    }
  }
`;
export const getLDapByGroup = /* GraphQL */ `
  query GetLDapByGroup($group: String!) {
    getLDapByGroup(group: $group) {
      group
      permissionPriority
      podRole
      rcGroup
    }
  }
`;
export const getPodByPodId = /* GraphQL */ `
  query GetPodByPodId($podId: ID!) {
    getPodByPodId(podId: $podId) {
      contact
      endTime
      jobLevel
      podId
      podTypeId
      rcGroup
      startTime
    }
  }
`;
export const getPodByPodTypeIdAndStartTime = /* GraphQL */ `
  query GetPodByPodTypeIdAndStartTime(
    $podTypeId: String!
    $startTime: AWSTimestamp
  ) {
    getPodByPodTypeIdAndStartTime(
      podTypeId: $podTypeId
      startTime: $startTime
    ) {
      contact
      endTime
      jobLevel
      podId
      podTypeId
      rcGroup
      startTime
    }
  }
`;
export const getPodSignupByPodIdAndInterviewer = /* GraphQL */ `
  query GetPodSignupByPodIdAndInterviewer(
    $interviewer: String!
    $podId: String!
  ) {
    getPodSignupByPodIdAndInterviewer(
      interviewer: $interviewer
      podId: $podId
    ) {
      interviewer
      podId
    }
  }
`;
export const getPodTypeByPodTypeId = /* GraphQL */ `
  query GetPodTypeByPodTypeId($podTypeId: ID!) {
    getPodTypeByPodTypeId(podTypeId: $podTypeId) {
      active
      description
      interviewTypes
      podTypeId
      rcGroup
    }
  }
`;
export const getScheduleByCandidateIdAndInterviewType = /* GraphQL */ `
  query GetScheduleByCandidateIdAndInterviewType(
    $candidateId: String
    $interviewType: InterviewType
  ) {
    getScheduleByCandidateIdAndInterviewType(
      candidateId: $candidateId
      interviewType: $interviewType
    ) {
      candidateId
      endTime
      interviewType
      interviewer
      podId
      queue
      startTime
      status
    }
  }
`;
export const getScheduleByPodIdAndCandidateId = /* GraphQL */ `
  query GetScheduleByPodIdAndCandidateId($candidateId: String, $podId: String) {
    getScheduleByPodIdAndCandidateId(candidateId: $candidateId, podId: $podId) {
      candidateId
      endTime
      interviewType
      interviewer
      podId
      queue
      startTime
      status
    }
  }
`;
export const getScheduleByPodIdAndStartTime = /* GraphQL */ `
  query GetScheduleByPodIdAndStartTime(
    $podId: String
    $startTime: AWSTimestamp
  ) {
    getScheduleByPodIdAndStartTime(podId: $podId, startTime: $startTime) {
      candidateId
      endTime
      interviewType
      interviewer
      podId
      queue
      startTime
      status
    }
  }
`;
export const getWhitelabelByRcGroup = /* GraphQL */ `
  query GetWhitelabelByRcGroup($rcGroup: String!) {
    getWhitelabelByRcGroup(rcGroup: $rcGroup) {
      rcGroup
      rulePath
    }
  }
`;
export const listCandidateByPodId = /* GraphQL */ `
  query ListCandidateByPodId($podId: String!) {
    listCandidateByPodId(podId: $podId) {
      candidateId
      hireId
      hireLink
      jobLevel
      jobTitle
      name
      podId
    }
  }
`;
export const listPodByPodTypeId = /* GraphQL */ `
  query ListPodByPodTypeId($podTypeId: String!) {
    listPodByPodTypeId(podTypeId: $podTypeId) {
      contact
      endTime
      jobLevel
      podId
      podTypeId
      rcGroup
      startTime
    }
  }
`;
export const listPodByRcGroup = /* GraphQL */ `
  query ListPodByRcGroup($rcGroup: String) {
    listPodByRcGroup(rcGroup: $rcGroup) {
      contact
      endTime
      jobLevel
      podId
      podTypeId
      rcGroup
      startTime
    }
  }
`;
export const listPodSignupByInterviewer = /* GraphQL */ `
  query ListPodSignupByInterviewer($interviewer: String!) {
    listPodSignupByInterviewer(interviewer: $interviewer) {
      interviewer
      podId
    }
  }
`;
export const listPodSignupByPodId = /* GraphQL */ `
  query ListPodSignupByPodId($podId: String!) {
    listPodSignupByPodId(podId: $podId) {
      interviewer
      podId
    }
  }
`;
export const listPodTypeByRcGroup = /* GraphQL */ `
  query ListPodTypeByRcGroup($rcGroup: String!) {
    listPodTypeByRcGroup(rcGroup: $rcGroup) {
      active
      description
      interviewTypes
      podTypeId
      rcGroup
    }
  }
`;
export const listScheduleByCandidateId = /* GraphQL */ `
  query ListScheduleByCandidateId($candidateId: String) {
    listScheduleByCandidateId(candidateId: $candidateId) {
      candidateId
      endTime
      interviewType
      interviewer
      podId
      queue
      startTime
      status
    }
  }
`;
export const listScheduleByInterviewer = /* GraphQL */ `
  query ListScheduleByInterviewer($interviewer: String) {
    listScheduleByInterviewer(interviewer: $interviewer) {
      candidateId
      endTime
      interviewType
      interviewer
      podId
      queue
      startTime
      status
    }
  }
`;
export const listScheduleByPodId = /* GraphQL */ `
  query ListScheduleByPodId($podId: String) {
    listScheduleByPodId(podId: $podId) {
      candidateId
      endTime
      interviewType
      interviewer
      podId
      queue
      startTime
      status
    }
  }
`;
export const scanCandidate = /* GraphQL */ `
  query ScanCandidate {
    scanCandidate {
      candidateId
      hireId
      hireLink
      jobLevel
      jobTitle
      name
      podId
    }
  }
`;
export const scanInterviewer = /* GraphQL */ `
  query ScanInterviewer {
    scanInterviewer {
      alias
      competencies
      email
      isBr
      isMgr
      isTech
      jobLevel
      jobTitle
      lastPod
      name
    }
  }
`;
export const scanLDap = /* GraphQL */ `
  query ScanLDap {
    scanLDap {
      group
      permissionPriority
      podRole
      rcGroup
    }
  }
`;
export const scanPod = /* GraphQL */ `
  query ScanPod {
    scanPod {
      contact
      endTime
      jobLevel
      podId
      podTypeId
      rcGroup
      startTime
    }
  }
`;
export const scanPodSignup = /* GraphQL */ `
  query ScanPodSignup {
    scanPodSignup {
      interviewer
      podId
    }
  }
`;
export const scanPodType = /* GraphQL */ `
  query ScanPodType {
    scanPodType {
      active
      description
      interviewTypes
      podTypeId
      rcGroup
    }
  }
`;
export const scanSchedule = /* GraphQL */ `
  query ScanSchedule {
    scanSchedule {
      candidateId
      endTime
      interviewType
      interviewer
      podId
      queue
      startTime
      status
    }
  }
`;

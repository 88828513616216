/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CandidateInput = {
  hireId?: string | null,
  hireLink?: string | null,
  jobLevel?: number | null,
  jobTitle?: string | null,
  name?: string | null,
  podId: string,
};

export type Candidate = {
  __typename: "Candidate",
  candidateId: string,
  hireId?: string | null,
  hireLink?: string | null,
  jobLevel?: number | null,
  jobTitle?: string | null,
  name?: string | null,
  podId: string,
};

export type PodInput = {
  contact?: string | null,
  endTime?: number | null,
  jobLevel?: Array< number | null > | null,
  podTypeId: string,
  rcGroup?: string | null,
  startTime?: number | null,
};

export type Pod = {
  __typename: "Pod",
  contact?: string | null,
  endTime?: number | null,
  jobLevel?: Array< number | null > | null,
  podId: string,
  podTypeId: string,
  rcGroup?: string | null,
  startTime?: number | null,
};

export type Interviewer = {
  __typename: "Interviewer",
  alias: string,
  competencies?: Array< string | null > | null,
  email?: string | null,
  isBr?: boolean | null,
  isMgr?: boolean | null,
  isTech?: boolean | null,
  jobLevel?: number | null,
  jobTitle?: string | null,
  lastPod?: number | null,
  name?: string | null,
};

export type LDap = {
  __typename: "LDap",
  group: string,
  permissionPriority?: number | null,
  podRole?: PodRole | null,
  rcGroup?: string | null,
};

export enum PodRole {
  Admin = "Admin",
  Admins = "Admins",
  Audit = "Audit",
  Interviewer = "Interviewer",
  Recruiter = "Recruiter",
  SuperAdmin = "SuperAdmin",
  Unauthenticated = "Unauthenticated",
  User = "User",
}


export type PodSignup = {
  __typename: "PodSignup",
  interviewer: string,
  podId: string,
};

export type PodType = {
  __typename: "PodType",
  active?: Array< InterviewType | null > | null,
  description?: string | null,
  interviewTypes?: string | null,
  podTypeId: string,
  rcGroup: string,
};

export enum InterviewType {
  BarRaiser = "BarRaiser",
  HiringManager = "HiringManager",
  LP = "LP",
  NonProserve = "NonProserve",
  Other = "Other",
  TechBreadth = "TechBreadth",
  TechDepth = "TechDepth",
}


export type Schedule = {
  __typename: "Schedule",
  candidateId?: string | null,
  endTime?: number | null,
  interviewType?: InterviewType | null,
  interviewer?: string | null,
  podId?: string | null,
  queue?: string | null,
  startTime?: number | null,
  status?: ScheduleStatus | null,
};

export enum ScheduleStatus {
  Created = "Created",
  Emailed = "Emailed",
}


export type Whitelabel = {
  __typename: "Whitelabel",
  rcGroup: string,
  rulePath?: string | null,
};

export type InterviewerInput = {
  competencies?: Array< string | null > | null,
  email?: string | null,
  isBr?: boolean | null,
  isMgr?: boolean | null,
  isTech?: boolean | null,
  jobLevel?: number | null,
  jobTitle?: string | null,
  lastPod?: number | null,
  name?: string | null,
};

export type LDapInput = {
  permissionPriority?: number | null,
  podRole?: PodRole | null,
  rcGroup?: string | null,
};

export type PodTypeInput = {
  active?: Array< InterviewType | null > | null,
  description?: string | null,
  interviewTypes?: string | null,
  rcGroup: string,
};

export type ScheduleInput = {
  endTime?: number | null,
  interviewer?: string | null,
  podId?: string | null,
  queue?: string | null,
  startTime?: number | null,
  status?: ScheduleStatus | null,
};

export type WhitelabelInput = {
  rulePath?: string | null,
};

export type SendEmailInput = {
  alias?: string | null,
  email?: string | null,
  input?: ScheduleInput | null,
  podType?: string | null,
};

export type isSuccess = {
  __typename: "isSuccess",
  message?: string | null,
  success: boolean,
};

export type CreateCandidateMutationVariables = {
  input?: CandidateInput | null,
};

export type CreateCandidateMutation = {
  createCandidate?:  {
    __typename: "Candidate",
    candidateId: string,
    hireId?: string | null,
    hireLink?: string | null,
    jobLevel?: number | null,
    jobTitle?: string | null,
    name?: string | null,
    podId: string,
  } | null,
};

export type CreatePodMutationVariables = {
  input?: PodInput | null,
};

export type CreatePodMutation = {
  createPod?:  {
    __typename: "Pod",
    contact?: string | null,
    endTime?: number | null,
    jobLevel?: Array< number | null > | null,
    podId: string,
    podTypeId: string,
    rcGroup?: string | null,
    startTime?: number | null,
  } | null,
};

export type DeleteCandidateMutationVariables = {
  candidateId: string,
};

export type DeleteCandidateMutation = {
  deleteCandidate?:  {
    __typename: "Candidate",
    candidateId: string,
    hireId?: string | null,
    hireLink?: string | null,
    jobLevel?: number | null,
    jobTitle?: string | null,
    name?: string | null,
    podId: string,
  } | null,
};

export type DeleteInterviewerMutationVariables = {
  alias: string,
};

export type DeleteInterviewerMutation = {
  deleteInterviewer?:  {
    __typename: "Interviewer",
    alias: string,
    competencies?: Array< string | null > | null,
    email?: string | null,
    isBr?: boolean | null,
    isMgr?: boolean | null,
    isTech?: boolean | null,
    jobLevel?: number | null,
    jobTitle?: string | null,
    lastPod?: number | null,
    name?: string | null,
  } | null,
};

export type DeleteLDapMutationVariables = {
  group: string,
};

export type DeleteLDapMutation = {
  deleteLDap?:  {
    __typename: "LDap",
    group: string,
    permissionPriority?: number | null,
    podRole?: PodRole | null,
    rcGroup?: string | null,
  } | null,
};

export type DeletePodMutationVariables = {
  podId: string,
};

export type DeletePodMutation = {
  deletePod?:  {
    __typename: "Pod",
    contact?: string | null,
    endTime?: number | null,
    jobLevel?: Array< number | null > | null,
    podId: string,
    podTypeId: string,
    rcGroup?: string | null,
    startTime?: number | null,
  } | null,
};

export type DeletePodSignupMutationVariables = {
  interviewer: string,
  podId: string,
};

export type DeletePodSignupMutation = {
  deletePodSignup?:  {
    __typename: "PodSignup",
    interviewer: string,
    podId: string,
  } | null,
};

export type DeletePodTypeMutationVariables = {
  podTypeId: string,
};

export type DeletePodTypeMutation = {
  deletePodType?:  {
    __typename: "PodType",
    active?: Array< InterviewType | null > | null,
    description?: string | null,
    interviewTypes?: string | null,
    podTypeId: string,
    rcGroup: string,
  } | null,
};

export type DeleteScheduleMutationVariables = {
  candidateId?: string | null,
  interviewType?: InterviewType | null,
};

export type DeleteScheduleMutation = {
  deleteSchedule?:  {
    __typename: "Schedule",
    candidateId?: string | null,
    endTime?: number | null,
    interviewType?: InterviewType | null,
    interviewer?: string | null,
    podId?: string | null,
    queue?: string | null,
    startTime?: number | null,
    status?: ScheduleStatus | null,
  } | null,
};

export type DeleteWhitelabelMutationVariables = {
  rcGroup: string,
};

export type DeleteWhitelabelMutation = {
  deleteWhitelabel?:  {
    __typename: "Whitelabel",
    rcGroup: string,
    rulePath?: string | null,
  } | null,
};

export type PutCandidateMutationVariables = {
  candidateId: string,
  input?: CandidateInput | null,
};

export type PutCandidateMutation = {
  putCandidate?:  {
    __typename: "Candidate",
    candidateId: string,
    hireId?: string | null,
    hireLink?: string | null,
    jobLevel?: number | null,
    jobTitle?: string | null,
    name?: string | null,
    podId: string,
  } | null,
};

export type PutInterviewerMutationVariables = {
  alias: string,
  input?: InterviewerInput | null,
};

export type PutInterviewerMutation = {
  putInterviewer?:  {
    __typename: "Interviewer",
    alias: string,
    competencies?: Array< string | null > | null,
    email?: string | null,
    isBr?: boolean | null,
    isMgr?: boolean | null,
    isTech?: boolean | null,
    jobLevel?: number | null,
    jobTitle?: string | null,
    lastPod?: number | null,
    name?: string | null,
  } | null,
};

export type PutLDapMutationVariables = {
  group: string,
  input?: LDapInput | null,
};

export type PutLDapMutation = {
  putLDap?:  {
    __typename: "LDap",
    group: string,
    permissionPriority?: number | null,
    podRole?: PodRole | null,
    rcGroup?: string | null,
  } | null,
};

export type PutPodMutationVariables = {
  input?: PodInput | null,
  podId: string,
};

export type PutPodMutation = {
  putPod?:  {
    __typename: "Pod",
    contact?: string | null,
    endTime?: number | null,
    jobLevel?: Array< number | null > | null,
    podId: string,
    podTypeId: string,
    rcGroup?: string | null,
    startTime?: number | null,
  } | null,
};

export type PutPodSignupMutationVariables = {
  interviewer: string,
  podId: string,
};

export type PutPodSignupMutation = {
  putPodSignup?:  {
    __typename: "PodSignup",
    interviewer: string,
    podId: string,
  } | null,
};

export type PutPodTypeMutationVariables = {
  input?: PodTypeInput | null,
  podTypeId: string,
};

export type PutPodTypeMutation = {
  putPodType?:  {
    __typename: "PodType",
    active?: Array< InterviewType | null > | null,
    description?: string | null,
    interviewTypes?: string | null,
    podTypeId: string,
    rcGroup: string,
  } | null,
};

export type PutScheduleMutationVariables = {
  candidateId?: string | null,
  input?: ScheduleInput | null,
  interviewType?: InterviewType | null,
};

export type PutScheduleMutation = {
  putSchedule?:  {
    __typename: "Schedule",
    candidateId?: string | null,
    endTime?: number | null,
    interviewType?: InterviewType | null,
    interviewer?: string | null,
    podId?: string | null,
    queue?: string | null,
    startTime?: number | null,
    status?: ScheduleStatus | null,
  } | null,
};

export type PutWhitelabelMutationVariables = {
  input?: WhitelabelInput | null,
  rcGroup: string,
};

export type PutWhitelabelMutation = {
  putWhitelabel?:  {
    __typename: "Whitelabel",
    rcGroup: string,
    rulePath?: string | null,
  } | null,
};

export type SendEmailOnCancelMutationVariables = {
  input?: SendEmailInput | null,
};

export type SendEmailOnCancelMutation = {
  sendEmailOnCancel?:  {
    __typename: "isSuccess",
    message?: string | null,
    success: boolean,
  } | null,
};

export type SendEmailOnConfirmedMutationVariables = {
  input?: SendEmailInput | null,
};

export type SendEmailOnConfirmedMutation = {
  sendEmailOnConfirmed?:  {
    __typename: "isSuccess",
    message?: string | null,
    success: boolean,
  } | null,
};

export type SendEmailOnSignupMutationVariables = {
  input?: SendEmailInput | null,
};

export type SendEmailOnSignupMutation = {
  sendEmailOnSignup?:  {
    __typename: "isSuccess",
    message?: string | null,
    success: boolean,
  } | null,
};

export type GetCandidateByCandidateIdQueryVariables = {
  candidateId: string,
};

export type GetCandidateByCandidateIdQuery = {
  getCandidateByCandidateId?:  {
    __typename: "Candidate",
    candidateId: string,
    hireId?: string | null,
    hireLink?: string | null,
    jobLevel?: number | null,
    jobTitle?: string | null,
    name?: string | null,
    podId: string,
  } | null,
};

export type GetInterviewerByAliasQueryVariables = {
  alias: string,
};

export type GetInterviewerByAliasQuery = {
  getInterviewerByAlias?:  {
    __typename: "Interviewer",
    alias: string,
    competencies?: Array< string | null > | null,
    email?: string | null,
    isBr?: boolean | null,
    isMgr?: boolean | null,
    isTech?: boolean | null,
    jobLevel?: number | null,
    jobTitle?: string | null,
    lastPod?: number | null,
    name?: string | null,
  } | null,
};

export type GetLDapByGroupQueryVariables = {
  group: string,
};

export type GetLDapByGroupQuery = {
  getLDapByGroup?:  {
    __typename: "LDap",
    group: string,
    permissionPriority?: number | null,
    podRole?: PodRole | null,
    rcGroup?: string | null,
  } | null,
};

export type GetPodByPodIdQueryVariables = {
  podId: string,
};

export type GetPodByPodIdQuery = {
  getPodByPodId?:  {
    __typename: "Pod",
    contact?: string | null,
    endTime?: number | null,
    jobLevel?: Array< number | null > | null,
    podId: string,
    podTypeId: string,
    rcGroup?: string | null,
    startTime?: number | null,
  } | null,
};

export type GetPodByPodTypeIdAndStartTimeQueryVariables = {
  podTypeId: string,
  startTime?: number | null,
};

export type GetPodByPodTypeIdAndStartTimeQuery = {
  getPodByPodTypeIdAndStartTime?:  {
    __typename: "Pod",
    contact?: string | null,
    endTime?: number | null,
    jobLevel?: Array< number | null > | null,
    podId: string,
    podTypeId: string,
    rcGroup?: string | null,
    startTime?: number | null,
  } | null,
};

export type GetPodSignupByPodIdAndInterviewerQueryVariables = {
  interviewer: string,
  podId: string,
};

export type GetPodSignupByPodIdAndInterviewerQuery = {
  getPodSignupByPodIdAndInterviewer?:  {
    __typename: "PodSignup",
    interviewer: string,
    podId: string,
  } | null,
};

export type GetPodTypeByPodTypeIdQueryVariables = {
  podTypeId: string,
};

export type GetPodTypeByPodTypeIdQuery = {
  getPodTypeByPodTypeId?:  {
    __typename: "PodType",
    active?: Array< InterviewType | null > | null,
    description?: string | null,
    interviewTypes?: string | null,
    podTypeId: string,
    rcGroup: string,
  } | null,
};

export type GetScheduleByCandidateIdAndInterviewTypeQueryVariables = {
  candidateId?: string | null,
  interviewType?: InterviewType | null,
};

export type GetScheduleByCandidateIdAndInterviewTypeQuery = {
  getScheduleByCandidateIdAndInterviewType?:  {
    __typename: "Schedule",
    candidateId?: string | null,
    endTime?: number | null,
    interviewType?: InterviewType | null,
    interviewer?: string | null,
    podId?: string | null,
    queue?: string | null,
    startTime?: number | null,
    status?: ScheduleStatus | null,
  } | null,
};

export type GetScheduleByPodIdAndCandidateIdQueryVariables = {
  candidateId?: string | null,
  podId?: string | null,
};

export type GetScheduleByPodIdAndCandidateIdQuery = {
  getScheduleByPodIdAndCandidateId?:  {
    __typename: "Schedule",
    candidateId?: string | null,
    endTime?: number | null,
    interviewType?: InterviewType | null,
    interviewer?: string | null,
    podId?: string | null,
    queue?: string | null,
    startTime?: number | null,
    status?: ScheduleStatus | null,
  } | null,
};

export type GetScheduleByPodIdAndStartTimeQueryVariables = {
  podId?: string | null,
  startTime?: number | null,
};

export type GetScheduleByPodIdAndStartTimeQuery = {
  getScheduleByPodIdAndStartTime?:  {
    __typename: "Schedule",
    candidateId?: string | null,
    endTime?: number | null,
    interviewType?: InterviewType | null,
    interviewer?: string | null,
    podId?: string | null,
    queue?: string | null,
    startTime?: number | null,
    status?: ScheduleStatus | null,
  } | null,
};

export type GetWhitelabelByRcGroupQueryVariables = {
  rcGroup: string,
};

export type GetWhitelabelByRcGroupQuery = {
  getWhitelabelByRcGroup?:  {
    __typename: "Whitelabel",
    rcGroup: string,
    rulePath?: string | null,
  } | null,
};

export type ListCandidateByPodIdQueryVariables = {
  podId: string,
};

export type ListCandidateByPodIdQuery = {
  listCandidateByPodId?:  Array< {
    __typename: "Candidate",
    candidateId: string,
    hireId?: string | null,
    hireLink?: string | null,
    jobLevel?: number | null,
    jobTitle?: string | null,
    name?: string | null,
    podId: string,
  } | null > | null,
};

export type ListPodByPodTypeIdQueryVariables = {
  podTypeId: string,
};

export type ListPodByPodTypeIdQuery = {
  listPodByPodTypeId?:  Array< {
    __typename: "Pod",
    contact?: string | null,
    endTime?: number | null,
    jobLevel?: Array< number | null > | null,
    podId: string,
    podTypeId: string,
    rcGroup?: string | null,
    startTime?: number | null,
  } | null > | null,
};

export type ListPodByRcGroupQueryVariables = {
  rcGroup?: string | null,
};

export type ListPodByRcGroupQuery = {
  listPodByRcGroup?:  Array< {
    __typename: "Pod",
    contact?: string | null,
    endTime?: number | null,
    jobLevel?: Array< number | null > | null,
    podId: string,
    podTypeId: string,
    rcGroup?: string | null,
    startTime?: number | null,
  } | null > | null,
};

export type ListPodSignupByInterviewerQueryVariables = {
  interviewer: string,
};

export type ListPodSignupByInterviewerQuery = {
  listPodSignupByInterviewer?:  Array< {
    __typename: "PodSignup",
    interviewer: string,
    podId: string,
  } | null > | null,
};

export type ListPodSignupByPodIdQueryVariables = {
  podId: string,
};

export type ListPodSignupByPodIdQuery = {
  listPodSignupByPodId?:  Array< {
    __typename: "PodSignup",
    interviewer: string,
    podId: string,
  } | null > | null,
};

export type ListPodTypeByRcGroupQueryVariables = {
  rcGroup: string,
};

export type ListPodTypeByRcGroupQuery = {
  listPodTypeByRcGroup?:  Array< {
    __typename: "PodType",
    active?: Array< InterviewType | null > | null,
    description?: string | null,
    interviewTypes?: string | null,
    podTypeId: string,
    rcGroup: string,
  } | null > | null,
};

export type ListScheduleByCandidateIdQueryVariables = {
  candidateId?: string | null,
};

export type ListScheduleByCandidateIdQuery = {
  listScheduleByCandidateId?:  Array< {
    __typename: "Schedule",
    candidateId?: string | null,
    endTime?: number | null,
    interviewType?: InterviewType | null,
    interviewer?: string | null,
    podId?: string | null,
    queue?: string | null,
    startTime?: number | null,
    status?: ScheduleStatus | null,
  } | null > | null,
};

export type ListScheduleByInterviewerQueryVariables = {
  interviewer?: string | null,
};

export type ListScheduleByInterviewerQuery = {
  listScheduleByInterviewer?:  Array< {
    __typename: "Schedule",
    candidateId?: string | null,
    endTime?: number | null,
    interviewType?: InterviewType | null,
    interviewer?: string | null,
    podId?: string | null,
    queue?: string | null,
    startTime?: number | null,
    status?: ScheduleStatus | null,
  } | null > | null,
};

export type ListScheduleByPodIdQueryVariables = {
  podId?: string | null,
};

export type ListScheduleByPodIdQuery = {
  listScheduleByPodId?:  Array< {
    __typename: "Schedule",
    candidateId?: string | null,
    endTime?: number | null,
    interviewType?: InterviewType | null,
    interviewer?: string | null,
    podId?: string | null,
    queue?: string | null,
    startTime?: number | null,
    status?: ScheduleStatus | null,
  } | null > | null,
};

export type ScanCandidateQuery = {
  scanCandidate?:  Array< {
    __typename: "Candidate",
    candidateId: string,
    hireId?: string | null,
    hireLink?: string | null,
    jobLevel?: number | null,
    jobTitle?: string | null,
    name?: string | null,
    podId: string,
  } | null > | null,
};

export type ScanInterviewerQuery = {
  scanInterviewer?:  Array< {
    __typename: "Interviewer",
    alias: string,
    competencies?: Array< string | null > | null,
    email?: string | null,
    isBr?: boolean | null,
    isMgr?: boolean | null,
    isTech?: boolean | null,
    jobLevel?: number | null,
    jobTitle?: string | null,
    lastPod?: number | null,
    name?: string | null,
  } | null > | null,
};

export type ScanLDapQuery = {
  scanLDap?:  Array< {
    __typename: "LDap",
    group: string,
    permissionPriority?: number | null,
    podRole?: PodRole | null,
    rcGroup?: string | null,
  } | null > | null,
};

export type ScanPodQuery = {
  scanPod?:  Array< {
    __typename: "Pod",
    contact?: string | null,
    endTime?: number | null,
    jobLevel?: Array< number | null > | null,
    podId: string,
    podTypeId: string,
    rcGroup?: string | null,
    startTime?: number | null,
  } | null > | null,
};

export type ScanPodSignupQuery = {
  scanPodSignup?:  Array< {
    __typename: "PodSignup",
    interviewer: string,
    podId: string,
  } | null > | null,
};

export type ScanPodTypeQuery = {
  scanPodType?:  Array< {
    __typename: "PodType",
    active?: Array< InterviewType | null > | null,
    description?: string | null,
    interviewTypes?: string | null,
    podTypeId: string,
    rcGroup: string,
  } | null > | null,
};

export type ScanScheduleQuery = {
  scanSchedule?:  Array< {
    __typename: "Schedule",
    candidateId?: string | null,
    endTime?: number | null,
    interviewType?: InterviewType | null,
    interviewer?: string | null,
    podId?: string | null,
    queue?: string | null,
    startTime?: number | null,
    status?: ScheduleStatus | null,
  } | null > | null,
};

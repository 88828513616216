/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCandidate = /* GraphQL */ `
  mutation CreateCandidate($input: CandidateInput) {
    createCandidate(input: $input) {
      candidateId
      hireId
      hireLink
      jobLevel
      jobTitle
      name
      podId
    }
  }
`;
export const createPod = /* GraphQL */ `
  mutation CreatePod($input: PodInput) {
    createPod(input: $input) {
      contact
      endTime
      jobLevel
      podId
      podTypeId
      rcGroup
      startTime
    }
  }
`;
export const deleteCandidate = /* GraphQL */ `
  mutation DeleteCandidate($candidateId: ID!) {
    deleteCandidate(candidateId: $candidateId) {
      candidateId
      hireId
      hireLink
      jobLevel
      jobTitle
      name
      podId
    }
  }
`;
export const deleteInterviewer = /* GraphQL */ `
  mutation DeleteInterviewer($alias: String!) {
    deleteInterviewer(alias: $alias) {
      alias
      competencies
      email
      isBr
      isMgr
      isTech
      jobLevel
      jobTitle
      lastPod
      name
    }
  }
`;
export const deleteLDap = /* GraphQL */ `
  mutation DeleteLDap($group: String!) {
    deleteLDap(group: $group) {
      group
      permissionPriority
      podRole
      rcGroup
    }
  }
`;
export const deletePod = /* GraphQL */ `
  mutation DeletePod($podId: ID!) {
    deletePod(podId: $podId) {
      contact
      endTime
      jobLevel
      podId
      podTypeId
      rcGroup
      startTime
    }
  }
`;
export const deletePodSignup = /* GraphQL */ `
  mutation DeletePodSignup($interviewer: String!, $podId: String!) {
    deletePodSignup(interviewer: $interviewer, podId: $podId) {
      interviewer
      podId
    }
  }
`;
export const deletePodType = /* GraphQL */ `
  mutation DeletePodType($podTypeId: ID!) {
    deletePodType(podTypeId: $podTypeId) {
      active
      description
      interviewTypes
      podTypeId
      rcGroup
    }
  }
`;
export const deleteSchedule = /* GraphQL */ `
  mutation DeleteSchedule($candidateId: String, $interviewType: InterviewType) {
    deleteSchedule(candidateId: $candidateId, interviewType: $interviewType) {
      candidateId
      endTime
      interviewType
      interviewer
      podId
      queue
      startTime
      status
    }
  }
`;
export const deleteWhitelabel = /* GraphQL */ `
  mutation DeleteWhitelabel($rcGroup: String!) {
    deleteWhitelabel(rcGroup: $rcGroup) {
      rcGroup
      rulePath
    }
  }
`;
export const putCandidate = /* GraphQL */ `
  mutation PutCandidate($candidateId: ID!, $input: CandidateInput) {
    putCandidate(candidateId: $candidateId, input: $input) {
      candidateId
      hireId
      hireLink
      jobLevel
      jobTitle
      name
      podId
    }
  }
`;
export const putInterviewer = /* GraphQL */ `
  mutation PutInterviewer($alias: String!, $input: InterviewerInput) {
    putInterviewer(alias: $alias, input: $input) {
      alias
      competencies
      email
      isBr
      isMgr
      isTech
      jobLevel
      jobTitle
      lastPod
      name
    }
  }
`;
export const putLDap = /* GraphQL */ `
  mutation PutLDap($group: String!, $input: LDapInput) {
    putLDap(group: $group, input: $input) {
      group
      permissionPriority
      podRole
      rcGroup
    }
  }
`;
export const putPod = /* GraphQL */ `
  mutation PutPod($input: PodInput, $podId: ID!) {
    putPod(input: $input, podId: $podId) {
      contact
      endTime
      jobLevel
      podId
      podTypeId
      rcGroup
      startTime
    }
  }
`;
export const putPodSignup = /* GraphQL */ `
  mutation PutPodSignup($interviewer: String!, $podId: String!) {
    putPodSignup(interviewer: $interviewer, podId: $podId) {
      interviewer
      podId
    }
  }
`;
export const putPodType = /* GraphQL */ `
  mutation PutPodType($input: PodTypeInput, $podTypeId: ID!) {
    putPodType(input: $input, podTypeId: $podTypeId) {
      active
      description
      interviewTypes
      podTypeId
      rcGroup
    }
  }
`;
export const putSchedule = /* GraphQL */ `
  mutation PutSchedule(
    $candidateId: String
    $input: ScheduleInput
    $interviewType: InterviewType
  ) {
    putSchedule(
      candidateId: $candidateId
      input: $input
      interviewType: $interviewType
    ) {
      candidateId
      endTime
      interviewType
      interviewer
      podId
      queue
      startTime
      status
    }
  }
`;
export const putWhitelabel = /* GraphQL */ `
  mutation PutWhitelabel($input: WhitelabelInput, $rcGroup: String!) {
    putWhitelabel(input: $input, rcGroup: $rcGroup) {
      rcGroup
      rulePath
    }
  }
`;
export const sendEmailOnCancel = /* GraphQL */ `
  mutation SendEmailOnCancel($input: SendEmailInput) {
    sendEmailOnCancel(input: $input) {
      message
      success
    }
  }
`;
export const sendEmailOnConfirmed = /* GraphQL */ `
  mutation SendEmailOnConfirmed($input: SendEmailInput) {
    sendEmailOnConfirmed(input: $input) {
      message
      success
    }
  }
`;
export const sendEmailOnSignup = /* GraphQL */ `
  mutation SendEmailOnSignup($input: SendEmailInput) {
    sendEmailOnSignup(input: $input) {
      message
      success
    }
  }
`;

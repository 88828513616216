import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router-dom'
import { Link } from '@mui/material'
import { ThemeProvider } from '@mui/material'
import { podTheme } from '../assets/theme'
function Copyright() {
  return (
    <Typography variant="body1" color="secondary" component="div">
      {'© '}
      {new Date().getFullYear()}
      {' Amazon Web Services, Inc. or its affiliates. All rights reserved.'}
    </Typography>
  )
}

export default function Footer() {
  const navigate = useNavigate()

  return (
    <ThemeProvider theme={podTheme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          component="footer"
          sx={{
            py: 1,
            backgroundColor: 'primary.main',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography
              variant="subtitle1"
              component="div"
              sx={{ color: 'secondary.contrastText' }}
            >
              <Link
                href="https://hire.amazon.com"
                target="_blank"
                underline="none"
                sx={{
                  color: 'secondary.contrastText',
                  borderRight: 1,
                  paddingLeft: 2,
                  paddingRight: 2,
                }}
              >
                Amazon Hire
              </Link>
              <Link
                href="https://beta.voop.proserve.aws.dev/"
                target="_blank"
                underline="none"
                sx={{
                  color: 'secondary.contrastText',
                  borderRight: 1,
                  paddingLeft: 2,
                  paddingRight: 2,
                }}
              >
                Voop
              </Link>
              <Link
                href="https://issues.amazon.com/issues/create?template=140d813b-8485-4b01-84b6-96bfea6e9d4f"
                target="blank"
                underline="none"
                sx={{
                  color: 'secondary.contrastText',
                  paddingLeft: 2,
                  paddingRight: 2,
                }}
              >
                Report Issue
              </Link>
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Copyright />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  )
}


import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { GridColumnVisibilityModel, GridFilterModel, GridSortModel } from '@mui/x-data-grid';

interface DataGridState {
    columnModel: GridColumnVisibilityModel;
    filterModel: GridFilterModel;
    sortModel: GridSortModel;
}

const initialState: DataGridState = {
    columnModel: {},
    sortModel: [],
    filterModel: {
        items: []
    }
};

const dataGridSlice = createSlice({
    name: 'dataGrid',
    initialState,
    reducers: {
        setSortModel: (state, action: PayloadAction<GridSortModel>) => {
            state.sortModel = action.payload;
        },
        setFilterModel: (state, action: PayloadAction<GridFilterModel>) => {
            state.filterModel = action.payload;
        },
        setColumnModel: (state, action: PayloadAction<GridColumnVisibilityModel>) => {
            state.columnModel = action.payload;
        },
    },
});

export const { setSortModel, setFilterModel, setColumnModel } = dataGridSlice.actions;

export default dataGridSlice.reducer;

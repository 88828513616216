import { API } from 'aws-amplify'
import { GraphQLResult } from '@aws-amplify/api-graphql'
import { GraphQLError } from 'graphql/error/GraphQLError'
import * as mutations from './graphql/mutations'
import * as queries from './graphql/queries'
import { getConsole } from '../utils/fns'
const console = getConsole()

export interface ReturnData {
  [name: string]: any
}

export class PodApi {
  async get(
    queryName: string,
    variables: { [name: string]: any }
  ): Promise<ReturnData> {
    return await this.$call('get', queryName, queries, variables)
  }

  async list(
    queryName: string,
    variables: { [name: string]: any }
  ): Promise<ReturnData> {
    return await this.$call('list', queryName, queries, variables)
  }

  async scan(queryName: string): Promise<ReturnData> {
    return await this.$call('scan', queryName, queries)
  }

  async put(
    mutationName: string,
    variables: { [name: string]: any }
  ): Promise<ReturnData> {
    return await this.$call('put', mutationName, mutations, variables)
  }

  async send(
    mutationName: string,
    variables: { [name: string]: any }
  ): Promise<ReturnData> {
    return await this.$call('send', mutationName, mutations, variables)
  }

  async update(
    mutationName: string,
    variables: { [name: string]: any }
  ): Promise<ReturnData> {
    return await this.$call('update', mutationName, mutations, variables)
  }

  async create(
    mutationName: string,
    variables: { [name: string]: any }
  ): Promise<ReturnData> {
    return await this.$call('create', mutationName, mutations, variables)
  }

  async delete(
    mutationName: string,
    variables: { [name: string]: any }
  ): Promise<ReturnData> {
    return await this.$call('delete', mutationName, mutations, variables)
  }

  public handleError($data: Partial<GraphQLResult> | string): void {
    const data: Partial<GraphQLError>[] = []
    if (typeof $data === 'string') {
      data.push({ message: $data })
    } else {
      const message = $data.errors
        ? $data.errors.map((e) => e.message).join('; ')
        : !$data.data
        ? 'Data not found'
        : null
      if (message) data.push({ message })
    }
    if (data.length) {
      const msgs = data.map((e) => e.message).join('; ')
      console.error(msgs)
      throw new Error(msgs)
    }
  }

  // TODO - Add support for subscriptions

  protected parseData(queryName: string, data: GraphQLResult): ReturnData {
    this.handleError(data)
    const obj = data?.data || {}
    if (!(queryName in obj)) {
      this.handleError(`Can't find query name ${queryName}`)
    }
    return obj[queryName]
  }

  protected async callAPI(query, variables?: { [name: string]: any }) {
    if (variables) {
      return await API.graphql({
        query,
        variables,
      })
    }
    return await API.graphql({
      query,
    })
  }

  protected async $call(
    operation: string,
    queryName: string,
    obj: { [name: string]: any },
    variables?: { [name: string]: any }
  ): Promise<ReturnData> {
    let queryTitle
    if (queryName in obj) {
      queryTitle = queryName
    } else {
      queryTitle = operation + queryName
    }
    const query = obj[queryTitle]
    if (!query) this.handleError(`Can't find operation ${queryTitle}`)
    const res = await this.callAPI(query, variables)
    return this.parseData(queryTitle, res as GraphQLResult)
  }
}

export const api = new PodApi()

import * as React from 'react'
import { Auth } from 'aws-amplify'
import { useNavigate } from 'react-router-dom'
import useAuth from '../hooks/useAuth'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import {
  Avatar,
  Container,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material'
import logo from '../assets/images/PodSchedulerLogo.svg'
import IconLogout from '@mui/icons-material/Logout'
import IconAccountCircle from '@mui/icons-material/AccountCircle'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { SitePaths } from '../routes'
import { PodUser } from '../api/user'
import { PodRole } from '../api/types'

const hasPermissionToShowAdminTab = (user: PodUser | undefined) => {
  const isDevEnv = process.env.NODE_ENV === 'development'

  if (user) {
    if (user.isManager) {
      return true
    }
    switch (user.role) {
      case PodRole.Admin:
        return true
      case PodRole.Recruiter:
        return true
      case PodRole.Admins:
        return true
      case PodRole.SuperAdmin:
        return true
      default:
        return isDevEnv
    }
  } else return false
}

export const Header: React.FC<{
  children?: any
  isInterviewer?: boolean
  isRecruiter?: boolean
}> = ({ isInterviewer = false, isRecruiter = false }) => {
  // Hi {user.attributes.given_name} <ArrowDropDownIcon />
  const { user } = useAuth()
  // console.log('HOME USER', user);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const navigate = useNavigate()
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const showAdminBar = hasPermissionToShowAdminTab(user)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleProfile = () => {
    setAnchorEl(null)
    navigate(SitePaths.Profile)
  }

  const signOut = async () => {
    await Auth.signOut({ global: true })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setAnchorEl(null)
      })
  }

  // console.log('Header')
  return (
    <AppBar position="fixed">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            {/* LOGO */}
            <div style={{ display: 'inline-flex' }}>
              <Button onClick={() => navigate(SitePaths.Root)}>
                <img
                  src={logo}
                  style={{ maxHeight: '32px', marginTop: '4px' }}
                  alt="Amazon Pod Scheduler Logo"
                />
              </Button>

              <div style={{ display: 'flex', marginLeft: '10px' }}>
                {isInterviewer && (
                  <Button
                    variant="text"
                    color="inherit"
                    sx={{ marginTop: '4px' }}
                    onClick={() => navigate(SitePaths.Interviewer)}
                  >
                    Interviewer
                  </Button>
                )}
                {isRecruiter && (
                  <Button
                    variant="text"
                    color="inherit"
                    sx={{ marginTop: '4px' }}
                    onClick={() => navigate(SitePaths.Recruiter)}
                  >
                    Recruiter
                  </Button>
                )}
                {showAdminBar && (
                  <Button
                    variant="text"
                    color="inherit"
                    sx={{ marginTop: '4px' }}
                    onClick={() => navigate(SitePaths.Admin)}
                  >
                    Admin
                  </Button>
                )}
              </div>
            </div>
            {!user ? (
              <Button
                color="inherit"
                size="small"
                variant="text"
                onClick={() => Auth.federatedSignIn()}
                sx={{ textTransform: 'none', fontSize: '1.1em' }}
              >
                Login
              </Button>
            ) : (
              <>
                <Button
                  color="inherit"
                  id="basic-button"
                  aria-controls="basic-menu"
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  sx={{ textTransform: 'none', fontSize: '1.1em' }}
                >
                  Hi {user.firstName} <ArrowDropDownIcon />
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'flex-start',
                      p: 1,
                    }}
                  >
                    <Avatar
                      alt={`${user.firstName} ${user.lastName}`}
                      src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${user.username}`}
                      sx={{ m: 1 }}
                    />
                    <Box sx={{ m: 1 }}>
                      <Typography
                        component={'p'}
                        variant="body1"
                      >{`${user.firstName} ${user.lastName}`}</Typography>
                      <Typography component={'p'} variant="body2">
                        {user.jobTitle}
                      </Typography>
                      <Typography component={'p'} variant="body2">
                        Level {user.jobLevel}
                      </Typography>
                    </Box>
                  </Box>
                  <Divider />
                  <MenuItem onClick={handleProfile} divider>
                    <ListItemIcon>
                      <IconAccountCircle />
                    </ListItemIcon>
                    <ListItemText primary="Profile" />
                  </MenuItem>

                  <MenuItem onClick={signOut}>
                    <ListItemIcon>
                      <IconLogout />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                  </MenuItem>
                </Menu>
              </>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

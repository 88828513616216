import { Box, Button, Chip, Container, Tab, Tabs } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import * as React from 'react'
import { useNavigate } from 'react-router'
import { Pod, Schedule } from '../react-app-env'
import PodDetailer from '../components/recruiter/PodDetailer'
import CandidateList from '../components/recruiter/CandidateList'
import { useLocation } from 'react-router-dom'
import { PodApi } from '../api'
import { replaceCandidates, setCandidateSchedules } from '../states/podSlice'
import { useAppDispatch, useAppSelector } from '../hooks/useRedux'
import { PromisePool } from '@supercharge/promise-pool'
import {
  getScheduleOfInterviewType,
  makeDefaultSchedules,
} from '../utils/ScheduleUtils'
import { listScheduleByCandidateId } from '../utils/dbQueries'
import { sortPodCandidates } from '../utils/ScheduleSlotTimeCalculator'
import { InterviewType } from '../api/types'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const api = new PodApi()

const PodDetails: React.FC = () => {
  const location: any = useLocation()
  const dispatch = useAppDispatch()

  const pods = useAppSelector((state) => state.pods)
  const pod = pods.filter((x) => x.id === location.state.pod.id)[0]
  const navigate = useNavigate()

  const [value, setValue] = React.useState(0)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  const [needSort, setNeedSort] = React.useState<boolean>(false)

  function getDefaultSchedulesForMissingSlots(
    pod: Pod,
    candidateId: string,
    existingSchedules: Schedule[]
  ) {
    const temp: Schedule[] = []
    const interviewerTypes = [
      InterviewType.HiringManager,
      InterviewType.BarRaiser,
      InterviewType.TechBreadth,
      InterviewType.TechDepth,
      InterviewType.NonProserve,
    ]
    const defaultSchedules = makeDefaultSchedules(
      pod,
      candidateId,
      pod.candidates.length
    )

    interviewerTypes.forEach((interviewerType) => {
      const matchingScheduleInterviewType = getScheduleOfInterviewType(
        existingSchedules,
        interviewerType
      )
      if (!matchingScheduleInterviewType) {
        const useDefault = getScheduleOfInterviewType(
          defaultSchedules,
          interviewerType
        )
        if (useDefault) {
          temp.push(useDefault)
        }
      }
    })
    return temp
  }

  async function populateSchedules(pod: Pod) {
    const candidates = [...pod.candidates]
    await PromisePool.withConcurrency(10)
      .for(candidates)
      .process(async (candidate) => {
        try {
          let schedules = await listScheduleByCandidateId(
            api,
            candidate.candidateId
          )
          const defaultSchedules = getDefaultSchedulesForMissingSlots(
            pod,
            candidate.candidateId,
            schedules
          )
          schedules = schedules.concat(defaultSchedules)
          dispatch(
            setCandidateSchedules({
              candidateId: candidate.candidateId,
              podId: pod.id,
              schedules: schedules,
            })
          )
        } catch (error) {
          console.warn(error)
        }
      })

    setNeedSort(true)
  }

  React.useEffect(() => {
    populateSchedules(pod)
  }, [])

  React.useEffect(() => {
    let canSort = true
    pod.candidates.forEach((candidate) => {
      if (candidate.schedules.length == 0) {
        canSort = false
      }
    })

    if (!canSort) return

    const sortedCandidates = sortPodCandidates(pod, [
      InterviewType.HiringManager,
      InterviewType.BarRaiser,
      InterviewType.TechDepth,
      InterviewType.TechBreadth,
      InterviewType.NonProserve,
    ])

    dispatch(replaceCandidates({ podId: pod.id, candidates: sortedCandidates }))
  }, [pod.candidates.length, needSort])

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '15px 0 0 0',
        }}
      >
        <Button
          aria-label="Add Pod(s)"
          variant="outlined"
          onClick={() => navigate(-1)}
          size="small"
        >
          <ArrowBackIcon /> Back to list
        </Button>
        <h3>
          POD Status: <Chip label={pod?.status} />
        </h3>
      </div>

      <Container maxWidth="xl" disableGutters>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Scheduler tabs"
          >
            <Tab label="Candidates" {...a11yProps(0)} />
            <Tab label="Details" color="secondary" {...a11yProps(1)} />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <CandidateList id={pod.id} />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <PodDetailer data={pod} />
        </TabPanel>
      </Container>
    </>
  )
}

export default PodDetails

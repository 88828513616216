import { OptionsObject, enqueueSnackbar } from 'notistack'

// Make sure SnackbarProvider is wrapping your App!!!

export enum Variant {
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
}

export function feedbackSnackbar(
  message: string,
  severity: Variant,
  preventDuplicate = false
) {
  const options: OptionsObject = {
    variant: severity,
    autoHideDuration: 9000,
    preventDuplicate: preventDuplicate,
  }
  enqueueSnackbar(message, options)
}

export function displayFeedback(
  state: Variant,
  successMessage = '',
  errorMessage = '',
  warningMessage = '',
  preventDuplicate = false
) {
  switch (state) {
    case Variant.SUCCESS:
      if (successMessage.length > 0) {
        feedbackSnackbar(successMessage, state)
      }
      break
    case Variant.ERROR:
      if (errorMessage.length > 0) {
        feedbackSnackbar(errorMessage, state)
      }
      break
    case Variant.WARNING:
    default:
      if (warningMessage.length > 0) {
        feedbackSnackbar(warningMessage, state)
      }
      break
  }
}

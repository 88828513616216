import { InterviewType, ScheduleStatus } from '../api/types'
import { Pod } from '../react-app-env'
import { Schedule } from '../react-app-env'
import { GetTimeSlotsByInterviewType } from './ScheduleSlotTimeCalculator'
import { equalInterviewTypes, getInterviewType } from './cleanClasses'

export function makeDefaultSchedules(
  pod: Pod,
  candidateId: string,
  indexInPod: number
): Schedule[] {
  //TODO set time based on pod
  const scheduleList: Schedule[] = []
  const types = [
    InterviewType.HiringManager,
    InterviewType.BarRaiser,
    InterviewType.TechDepth,
    InterviewType.TechBreadth,
    InterviewType.NonProserve,
  ]
  const timeSlots = GetTimeSlotsByInterviewType(pod, types, indexInPod)
  types.forEach((interviewType) => {
    const schedule: Schedule = {
      candidateId: candidateId,
      endTime: timeSlots[interviewType].endTime,
      interviewType: getInterviewType(interviewType),
      interviewer: '<empty>',
      podId: pod.id,
      queue: [],
      startTime: timeSlots[interviewType].startTime,
      scheduleStatus: ScheduleStatus.Created,
    }
    scheduleList.push(schedule)
  })
  return scheduleList
}

export function getScheduleOfInterviewType(
  schedules: Schedule[],
  interviewType: InterviewType
): Schedule | undefined {
  return schedules.find((schedule) =>
    equalInterviewTypes(schedule.interviewType, interviewType)
  )
}

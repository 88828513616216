import { PodApi } from '../api'
import {
  InterviewerDetails,
  LDAP,
  PodDetails,
  PodType,
  Schedule,
} from '../react-app-env'
import {
  Candidate as CandidateRaw,
  InterviewType,
  Pod as PodRaw,
} from '../api/types'
import {
  cleanInterviewer,
  cleanLDap,
  cleanPodType,
  cleanSchedules,
} from './cleanClasses'

export async function getPodList(api: PodApi): Promise<PodRaw[]> {
  try {
    const res = (await api.scan('Pod')) as PodRaw[]
    return res
  } catch (error) {
    console.warn('Error occured on api scan of pods.', error)
  }
  return []
}

export const getCandidateList = async (
  api: PodApi,
  pod: PodDetails
): Promise<CandidateRaw[]> => {
  const res = (await api.list('CandidateByPodId', {
    podId: pod.podId,
  })) as CandidateRaw[]
  return res
}

export async function getPodTypes(api: PodApi): Promise<PodType[]> {
  const temp: PodType[] = []
  const result = await api.scan('PodType')

  result.forEach((typeRaw) => {
    const type = cleanPodType(typeRaw)
    if (type) {
      temp.push(type)
    }
  })

  return temp
}

export async function listScheduleByCandidateId(
  api: PodApi,
  candidateId: string
): Promise<Schedule[]> {
  const schedulesRaw = await api.list('ScheduleByCandidateId', {
    candidateId: candidateId,
  })
  const schedules = cleanSchedules(schedulesRaw)

  if (schedulesRaw.length != schedules.length) {
    console.warn(
      'Not all schedules imported properly.',
      schedulesRaw,
      schedules
    )
  }
  return schedules
}

export async function listSchedulesByPodIdAndByInterviewType(
  api: PodApi,
  podId: string,
  interviewType: InterviewType | InterviewType
) {
  const schedules = cleanSchedules(
    await api.list('ScheduleByPodId', {
      podId: podId,
    })
  )

  return schedules?.filter(
    (schedule) => schedule.interviewType.valueOf() == interviewType.valueOf()
  )
}

export async function getInterviewer(
  api: PodApi,
  alias: string
): Promise<InterviewerDetails | null> {
  const results = await api.get('InterviewerByAlias', { alias: alias })
  const interviewer = cleanInterviewer(results)
  return interviewer
}

export async function scanLDap(api: PodApi): Promise<LDAP[]> {
  const LDAPs: LDAP[] = []
  const results = await api.scan('LDap')
  if (results) {
    results.forEach((result: LDAP) => {
      cleanLDap(result)
      LDAPs.push(result)
    })
  }
  return LDAPs
}

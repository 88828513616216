import { Box, Container, Tab, Tabs, Typography } from '@mui/material'
import * as React from 'react'
import useAuth from '../hooks/useAuth'
import PodList from '../components/interviewer/PodList'
import PendingList from '../components/interviewer/PendingList'
import { ConfirmedList } from '../components/interviewer/ConfirmedList'
import { PodDate as ConfirmedPodDates } from '../components/interviewer/ConfirmedList'
import { PodApi } from '../api/index'
import { cleanCandidate } from '../utils/cleanClasses'

const api = new PodApi()

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const PodScheduler: React.FC<Record<string, never>> = () => {
  const { user } = useAuth()
  if (!user) {
    return <div>Please login first</div>
  }
  const alias = user.alias
  const [value, setValue] = React.useState(0)
  const [confirmPodList, addConfirmedPodDate] = React.useState<
    ConfirmedPodDates[]
  >([])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  function handleConfirmAsyncUpdate(newArray: ConfirmedPodDates[]) {
    addConfirmedPodDate(newArray)
  }

  // Call this on first render only
  React.useEffect(() => {
    // Gather confirmed sign-ups
    const temp: ConfirmedPodDates[] = []
    if (user === undefined) {
      return
    }

    const getDataForConfirmedList = async () => {
      const result = await api.get('listScheduleByInterviewer', {
        interviewer: alias,
      })
      for (let i = 0; i < result.length; i++) {
        const candidateQuery = await api.get('getCandidateByCandidateId', {
          candidateId: result[i].candidateId,
        })
        const candidate = cleanCandidate(candidateQuery)
        if (candidate) {
          const candidateName = candidate.name
          const resultStartDate = new Date(result[i].startTime)
          const resultEndDate = new Date(result[i].endTime)
          const temp_time =
            resultStartDate.toLocaleTimeString() +
            ' - ' +
            resultEndDate.toLocaleTimeString()
          if (resultEndDate.getTime() > Date.now()) {
            temp.push({
              date: resultStartDate.toLocaleDateString(),
              rc: 'Cedric Lok',
              status: 'Confirmed',
              time: temp_time,
              candidate: candidateName,
            })
          }
        } else {
          console.error(
            'No record found for CandidateId:',
            result[i].candidateId
          )
        }
      }
      handleConfirmAsyncUpdate(temp)
    }

    getDataForConfirmedList()
      // print out errors
      .catch(console.error)
  }, [])

  return (
    <>
      <Typography variant="h4" sx={{ marginTop: 12 }}>
        Pod Signups
      </Typography>

      <Container maxWidth="xl" disableGutters sx={{ mt: 2 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Scheduler tabs"
          >
            <Tab label="Open" color="secondary" {...a11yProps(0)} />
            <Tab label="Pending" {...a11yProps(1)} />
            <Tab label="Confirmed" {...a11yProps(2)} />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <PodList />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <PendingList alias={alias} />
        </TabPanel>

        <TabPanel value={value} index={2}>
          <ConfirmedList rows={confirmPodList} />
        </TabPanel>
      </Container>
    </>
  )
}

export default PodScheduler

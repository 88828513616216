import React, { useEffect, useState } from 'react'
import { InterviewerDetails } from '../../react-app-env'
import { Paper, TableContainer } from '@mui/material'
import { DataGrid, GridColDef, GridComparatorFn } from '@mui/x-data-grid'
import { Link } from 'react-router-dom'
import { renderCompetencies } from '../../utils/displayUtils'

interface Props {
  data: InterviewerDetails[]
}

const compareCompetenciesForSorting: GridComparatorFn<string[]> = (c1, c2) => {
  return c1.join(', ').localeCompare(c2.join(', '))
}

const InterviewerList: React.FC<Props> = ({ data }) => {
  const [interviewers, setInterviewers] = useState<InterviewerDetails[]>()
  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', flex: 6 },
    { field: 'jobTitle', headerName: 'Job Title', flex: 6 },
    { field: 'alias', flex: 4 },
    { field: 'jobLevel', headerName: 'Job Level', flex: 2 },
    {
      field: 'competencies',
      headerName: 'Competencies',
      flex: 4,
      sortComparator: compareCompetenciesForSorting,
      valueGetter: function (params) {
        return renderCompetencies(params.row.competencies)
      },
    },
    {
      field: 'edit',
      headerName: 'Edit',
      flex: 2,
      renderCell(params) {
        return (
          <Link
            to={`/secure/admin/interviewer/${params.row.alias}`}
            state={{
              alias: params.row.alias,
            }}
          >
            Edit
          </Link>
        )
      },
      sortable: false,
      align: 'center',
    },
  ]

  useEffect(() => {
    setInterviewers(data)
  }, [data, interviewers])
  return (
    <TableContainer component={Paper} sx={{ padding: '0', height: '100vh' }}>
      <DataGrid
        rows={data}
        columns={columns}
        getRowId={(row: any) => row.alias}
      />
    </TableContainer>
  )
}

export default InterviewerList

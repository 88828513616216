import * as React from 'react'
import useAuth from './hooks/useAuth'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { SitePaths } from './routes'
import { PodApi } from './api'
import { getPodTypes } from './utils/dbQueries'
import { useAppDispatch } from './hooks/useRedux'
import { setState } from './states/PodTypeSlice'
import store from './store'

const api = new PodApi()

async function setPodTypeState(api: PodApi, dispatch) {
  const podTypes = await getPodTypes(api)
  dispatch(setState(podTypes))
}

const PrivateRoute: React.FC = () => {
  const { user, userRole } = useAuth()
  const location = useLocation()

  if (store.getState().podTypes.length == 0) {
    const dispatch = useAppDispatch()
    setPodTypeState(api, dispatch)
  }

  return user ? (
    user.isAuthenticated(userRole) ? (
      <Outlet />
    ) : (
      <Navigate to={SitePaths.Public} />
    )
  ) : (
    <Navigate to={SitePaths.Login} state={{ from: location }} />
  )
}

export default PrivateRoute

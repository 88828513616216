import * as React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Toolbar from '@mui/material/Toolbar'
import Modal from '@mui/material/Modal'
import { AppBar, Container, Grid, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import TextField from '@mui/material/TextField'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import StaticDatePicker from '@mui/lab/StaticDatePicker'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { v4 as uuidv4 } from 'uuid'
import { PodApi } from '../api'
import store from '../store'

interface AddPodsModalProps {
  isOpen: boolean
  onClose: any
}

export interface CloseModalStruct {
  action: string
  pods: PodItem[]
}

interface PodItem {
  blockTime: PodTypeTimeBlock | null
  endDate: Date
  id: string
  levels: number[]
  startDate: Date
  type: string
}

const createPodItem = (
  id: string,
  startDate: Date,
  endDate: Date,
  levels: number[],
  type: string,
  blockTime: PodTypeTimeBlock | null
): PodItem => {
  return {
    id,
    startDate,
    endDate,
    levels,
    type,
    blockTime,
  }
}

interface PodTypeTimeBlock {
  blockName: string
  blockValues: string
  endHour: number
  endMin: number
  startHour: number
  startMin: number
}

const createPodTypeTimeBlock = (
  blockName: string,
  blockValues: string,
  startHour: number,
  startMin: number,
  endHour: number,
  endMin: number
): PodTypeTimeBlock => {
  return {
    blockName,
    blockValues,
    startHour,
    startMin,
    endHour,
    endMin,
  }
}

const CST = 5 // UTC -> CST conversion
const timeBlocks = [
  createPodTypeTimeBlock(
    'Block A-1: 9:00AM - 2:30PM CST',
    '9:00|14:30|CST',
    9 + CST,
    0,
    2 + 12 + CST,
    30
  ),
  createPodTypeTimeBlock(
    'Block A-2: 9:30AM - 3:00PM CST ',
    '9:30|14:30|CST',
    9 + CST,
    30,
    3 + 12 + CST,
    0
  ),
  createPodTypeTimeBlock(
    'Block B-1: 12:00PM - 5:30PM CST',
    '12:00|17:30|CST',
    12 + CST,
    0,
    5 + 12 + CST,
    30
  ),
  createPodTypeTimeBlock(
    'Block B-2: 12:30AM - 6:00PM CST',
    '12:30|17:30|CST',
    12 + CST,
    30,
    6 + 12 + CST,
    0
  ),
]

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '1050px',
  bgcolor: 'background.paper',
  boxShadow: 6,
  borderRadius: '10px',
}

const api = new PodApi()

const AddPodsModal: React.FC<AddPodsModalProps> = ({ isOpen, onClose }) => {
  const [scheduledDay, setValue] = React.useState<Date>(new Date())
  const [podType, setPodType] = React.useState<string>('')
  const [podBlocksToBeCreated, setList] = React.useState<PodItem[]>([])
  const [podTypeSelectIsDisabled, setPodTypeSelectIsDisabled] =
    React.useState<boolean>(false)
  const [addPodButtonIsDisabled, setAddPodButtonIsDisabled] =
    React.useState<boolean>(true)
  const [podTypeSelection, setPodTypeSelection] =
    React.useState<typeof MenuItem>()
  const [selectedValue, setSelectedValue] = React.useState<any>('default')

  React.useEffect(() => {
    if (podBlocksToBeCreated.length) {
      setPodTypeSelectIsDisabled(true)
    } else {
      setPodTypeSelectIsDisabled(false)
    }
  }, [])

  const addDateToQue = () => {
    const tmpId = uuidv4()
    setList((podListingDate) => {
      return [
        ...podListingDate,
        createPodItem(
          tmpId,
          scheduledDay,
          new Date(scheduledDay),
          [],
          podType,
          null
        ),
      ]
    })
  }

  const reset = () => {
    setValue(new Date())
    setList([])
    setPodType('')
    setAddPodButtonIsDisabled(true)
  }

  const handleClose = (event: any, reason: string) => {
    reset()
    onClose({
      action: reason,
    })
  }

  const handleCloseClick = () => {
    reset()
    onClose({
      action: 'User Cancelled',
    })
  }

  const handleSave = () => {
    const tmpArr = [...podBlocksToBeCreated]
    reset()
    onClose({
      action: 'save',
      pods: tmpArr,
    })
  }

  const handlePodTypeSelection = (event: SelectChangeEvent) => {
    if (event.target.value) setAddPodButtonIsDisabled(false)
    setPodType(event.target.value as string)
  }

  const handleTimeBlockSelection = (event: SelectChangeEvent) => {
    const value = JSON.parse(event.target.value) as {
      id: string
      timeBlock: PodTypeTimeBlock | null
    }
    const id = value.id
    const input = value.timeBlock
    const newArr: PodItem[] = podBlocksToBeCreated.map((pod) => {
      if (pod.id === id) {
        if (input) {
          pod.startDate.setUTCHours(input.startHour)
          pod.startDate.setUTCMinutes(input.startMin)
          pod.endDate.setUTCHours(input.endHour)
          pod.endDate.setUTCMinutes(input.endMin)
        }
        return {
          ...pod,
          blockTime: input,
          startDate: pod.startDate,
        }
      }
      return pod
    })
    setList(newArr)
  }

  function checkTimeBlocksValidValues() {
    for (const item of podBlocksToBeCreated) {
      if (!item.blockTime) return false
    }
    return true
  }

  function checkLevelValidValues() {
    for (const item of podBlocksToBeCreated) {
      if (item.levels.length == 0) return false
    }
    return true
  }

  const handleRemovePod = (id: string) => {
    setList((pods) => pods.filter((item) => item.id !== id))
  }

  const handleLevelSelection = (
    e: React.MouseEvent<HTMLElement>,
    v: number[],
    id: string
  ) => {
    const newArr: PodItem[] = podBlocksToBeCreated.map((pod) => {
      if (pod.id === id) {
        return { ...pod, levels: v.sort((a, b) => a - b) }
      }

      return pod
    })
    setList(newArr)
  }

  const createTimeBlockDropdown = (_podType: string, _id: string) => {
    //const blocks = timeBlocks.filter((item) => item.type === _podType)

    const _pod = podBlocksToBeCreated.find(({ id }) => id === _id)
    const defaultSelectedValue = JSON.stringify({ id: _id, timeBlock: null })

    const selectedValue =
      _pod && _pod.blockTime
        ? JSON.stringify({ id: _id, timeBlock: _pod.blockTime })
        : defaultSelectedValue
    return (
      <FormControl fullWidth>
        <Select
          //@ts-ignore - necessary to load object into value
          value={selectedValue}
          onChange={handleTimeBlockSelection}
          size="small"
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          sx={{ textAlign: 'left' }}
        >
          <MenuItem key={0} value={defaultSelectedValue} autoFocus={true}>
            -- Please select time block --
          </MenuItem>
          {timeBlocks.map((item, idx) => {
            return (
              //@ts-ignore - necessary to load object into value
              <MenuItem
                key={idx}
                value={JSON.stringify({ id: _id, timeBlock: item })}
              >
                {item.blockName}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    )
  }

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box sx={style}>
        <AppBar
          position="static"
          color="secondary"
          sx={{ borderRadius: '10px 10px 0 0' }}
        >
          <Container
            sx={{
              width: '100%',
              paddingRight: '12px !important',
              paddingLeft: '12px !important',
            }}
          >
            <Toolbar
              disableGutters
              variant="dense"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ mr: 2, display: 'flex' }}
              >
                Add Pods
              </Typography>
              <IconButton aria-label="close" onClick={handleCloseClick}>
                <CloseIcon fontSize="inherit" htmlColor="#fff" />
              </IconButton>
            </Toolbar>
          </Container>
        </AppBar>

        <Container maxWidth="xl" sx={{ p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="pod-type-label">Pod Type</InputLabel>
                <Select
                  labelId="pod-type-lable"
                  id="pod-type-select"
                  value={podType}
                  label="Pod Type"
                  onChange={handlePodTypeSelection}
                  disabled={podTypeSelectIsDisabled}
                >
                  {store.getState().podTypes.map((item) => {
                    return item.podTypeId ? (
                      <MenuItem key={item.podTypeId} value={item.podTypeId}>
                        {item.podTypeId}
                      </MenuItem>
                    ) : (
                      ''
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={5}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  openTo="day"
                  value={scheduledDay}
                  onChange={(newValue) => {
                    if (newValue) {
                      setValue(newValue)
                    }
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>

              <Button
                onClick={addDateToQue}
                variant="contained"
                size="small"
                sx={{ width: '100%', maxWidth: '320px', textAlign: 'center' }}
                disabled={addPodButtonIsDisabled}
              >
                Add date to list
              </Button>
            </Grid>
            <Grid
              item
              xs={7}
              sx={{ maxHeight: '300px', overflow: 'auto', marginTop: '10px' }}
            >
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 350 }} aria-label="New Pods List">
                  <TableBody>
                    {podBlocksToBeCreated.map((pod) => (
                      <TableRow key={pod.id}>
                        <TableCell component="th" scope="row">
                          {`${
                            pod.startDate ? pod.startDate?.getMonth() + 1 : ''
                          }/${pod.startDate?.getDate()}/${pod.startDate?.getFullYear()}`}
                        </TableCell>
                        <TableCell align="right">
                          <ToggleButtonGroup
                            value={pod.levels}
                            onChange={(e, v) =>
                              handleLevelSelection(e, v, pod.id)
                            }
                            aria-label="Job Level Selection"
                            size="small"
                          >
                            <ToggleButton value={4} aria-label="L4">
                              L4
                            </ToggleButton>
                            <ToggleButton value={5} aria-label="L5">
                              L5
                            </ToggleButton>
                            <ToggleButton value={6} aria-label="L6">
                              L6
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </TableCell>
                        <TableCell align="right">
                          {createTimeBlockDropdown(podType, pod.id)}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton onClick={() => handleRemovePod(pod.id)}>
                            <CloseIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Container>

        <Box
          sx={{
            borderRadius: '0,0, 10px, 10px',
            p: 2,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            borderTop: '1px solid rgba(0,0,0,.25)',
          }}
        >
          <Button onClick={handleCloseClick}>Cancel</Button>
          <Button
            variant="outlined"
            sx={{ ml: 2 }}
            onClick={handleSave}
            disabled={
              podBlocksToBeCreated &&
              podBlocksToBeCreated.length &&
              checkTimeBlocksValidValues() &&
              checkLevelValidValues()
                ? false
                : true
            }
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default AddPodsModal

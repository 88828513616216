import * as React from 'react'
import { CircularProgress, IconButton, Tooltip } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import EmailIcon from '@mui/icons-material/Email'
import { FreeSolo } from './AutoCompleteFreeSolo'
import { Schedule as ScheduleType } from '../../react-app-env'
import { renderTimeRange } from '../../utils'
import { ScheduleStatus } from '../../api/types'

export interface InterviewerSlotProps {
  handleEditAlias
  handleInterviewerInputChange?
  schedule?: ScheduleType
}

interface SlotProps {
  handleEditAlias
  handleInterviewerInputChange?
  schedule: ScheduleType
}

const EmailedIcon = (
  <Tooltip title="Confirm email sent">
    <IconButton size="small">
      <EmailIcon fontSize="inherit" />
    </IconButton>
  </Tooltip>
)

export const InterviewerSlot: React.FC<InterviewerSlotProps> = ({
  schedule,
  handleEditAlias,
  handleInterviewerInputChange,
}) => {
  const result = schedule ? (
    <Slot
      schedule={schedule}
      handleEditAlias={handleEditAlias}
      handleInterviewerInputChange={handleInterviewerInputChange}
    />
  ) : (
    <CircularProgress disableShrink={true} size={20}></CircularProgress>
  )

  return <div>{result}</div>
}

const Slot: React.FC<SlotProps> = ({
  schedule,
  handleEditAlias,
  handleInterviewerInputChange,
}) => {
  function interviewerHandleSelectChange(
    event: React.SyntheticEvent,
    value: string,
    reason: any
  ) {
    if (handleInterviewerInputChange) {
      const oldSchedule = schedule
      const newSchedule = { ...schedule }
      newSchedule.interviewer = value.length > 0 ? value : '<empty>'
      handleInterviewerInputChange(
        schedule.candidateId,
        schedule.interviewType,
        newSchedule,
        oldSchedule
      )
    }
  }

  const queueSelectDisplay = (
    <div>
      <FreeSolo
        value={
          schedule.interviewer != '<empty>' ? schedule.interviewer : undefined
        }
        label={schedule.interviewType}
        options={schedule.queue}
        onInputChange={interviewerHandleSelectChange}
      />
    </div>
  )

  const interviewerEntryDisplay = (
    <div>
      {schedule && schedule.interviewer && schedule.interviewer !== '<empty>'
        ? schedule.interviewer
        : ''}
      <IconButton size="small" onClick={(e) => handleEditAlias(e, schedule)}>
        <EditIcon fontSize="inherit" />
      </IconButton>
    </div>
  )

  let result
  if (schedule.queue && schedule.queue.length > 0) {
    result = queueSelectDisplay
  } else {
    result = interviewerEntryDisplay
  }

  let scheduleStatus
  if (schedule.scheduleStatus === ScheduleStatus.Emailed) {
    scheduleStatus = EmailedIcon
  }

  const timeDisplay = (
    <div>
      {renderTimeRange(schedule.startTime, schedule.endTime)}
      {scheduleStatus}
    </div>
  )

  return (
    <div>
      {result}
      {timeDisplay}
    </div>
  )
}

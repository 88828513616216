import React from 'react'
import { useRoutes } from 'react-router-dom'
import { AuthProvider } from './hooks/useAuth'
import { ThemeProvider } from '@mui/material'
import { podTheme } from './assets/theme'
import { appRoutes } from './routes'

const App: React.FC = () => {
  const routes = useRoutes(appRoutes)
  return (
    <ThemeProvider theme={podTheme}>
      <AuthProvider>{routes}</AuthProvider>
    </ThemeProvider>
  )
}

export default App

import React, { useEffect } from 'react'
import InterviewerList from '../components/admin/InterviewerList'
import { useLazyRef } from '../utils/useLazyRef'
import { PodApi } from '../api'
import { InterviewerDetails } from '../react-app-env'
import {} from '../api/'
import { cleanInterviewer } from '../utils/cleanClasses'
import { Interviewer as InterviewerRaw } from '../api/types'
import { useAppDispatch, useAppSelector } from '../hooks/useRedux'
import { setState } from '../states/interviewerSlice'

async function GetInterviewers(api: PodApi): Promise<InterviewerDetails[]> {
  const interviewers: InterviewerDetails[] = []
  const response: InterviewerRaw[] = (await api.scan(
    'Interviewer'
  )) as InterviewerRaw[]
  response.forEach((interviewerRaw) => {
    const interviewer = cleanInterviewer(interviewerRaw)
    if (interviewer) {
      interviewers.push(interviewer)
    }
  })
  return interviewers
}

async function setInterviewerState(api: PodApi, dispatch) {
  const interviewers = await GetInterviewers(api)
  dispatch(setState(interviewers))
}

export const AdminDashboard: React.FC = () => {
  const apiRef = useLazyRef(() => new PodApi())
  const dispatch = useAppDispatch()
  const interviewers = useAppSelector((state) => state.interviewers)

  useEffect(() => {
    setInterviewerState(apiRef.current, dispatch)
  }, [])

  return (
    <div>
      <p>You have reached the Admin Page!</p>
      <InterviewerList data={interviewers} />
    </div>
  )
}

export default AdminDashboard

import * as React from 'react'
import useAuth from '../hooks/useAuth'
import { renderWithSpacing } from '../utils/displayUtils'

const Profile: React.FC = () => {
  const { user } = useAuth()

  return (
    <div>
      {user ? (
        <>
          <h1>Profile</h1>
          <p>
            Alias:{' '}
            <a href={user.getPhoneTool()} target="_blank" rel="noreferrer">
              {user.username}
            </a>
          </p>
          <p>First Name: {user.firstName}</p>
          <p>Last Name: {user.lastName}</p>
          <p>
            Email: <a href={`mailto:${user?.email}`}>{user.email}</a>
          </p>
          <p>Job Title: {user.jobTitle}</p>
          <p>Job Level: {user.level}</p>
          <p>Is Manager: {user.isManager ? 'Yes' : 'No'}</p>
          <p>
            Manager:{' '}
            <a
              href={user.getPhoneTool(user.managerAlias)}
              target="_blank"
              rel="noreferrer"
            >
              {user.managerName}
            </a>
          </p>
          <p>
            Manager Alias:{' '}
            <a
              href={user?.getPhoneTool(user.managerAlias)}
              target="_blank"
              rel="noreferrer"
            >
              {user.managerAlias}
            </a>
          </p>
          <p>Interviewer: {user.isInterviewer() ? 'Yes' : 'No'}</p>
          <p>
            Competencies: {user.competencies.toString().split(',').join(', ')}
          </p>
          <p>Recruiter: {user.isRecruiter() ? 'Yes' : 'No'}</p>
          <p>Is Bar Raiser: {user.isBarRaiser ? 'Yes' : 'No'}</p>
          <p>Role: {renderWithSpacing(user.role)}</p>
        </>
      ) : (
        <h1>User Not Found</h1>
      )}
    </div>
  )
}

export default Profile

import React from 'react'
import { Header } from './Header'
import { Outlet } from 'react-router-dom'
import Footer from './Footer'
import { Box, Container } from '@mui/material'
import { Loader } from './Loader'
import useAuth from '../hooks/useAuth'

const Layout: React.FC = () => {
  const { loading, userRole, user } = useAuth()

  return (
    <div
      style={{
        width: '100vw',
        height: '100%',
        margin: '0px !imporant',
        padding: '0px !imporant',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Header
        isInterviewer={user?.isInterviewer(userRole)}
        isRecruiter={user?.isRecruiter(userRole)}
      />
      <Box
        sx={{
          flex: '1 1 auto',
          overflowX: 'auto',
          marginTop: '72px',
          width: '100%',
        }}
      >
        <Container maxWidth="xl">
          <Loader active={loading} size="3rem">
            <Outlet />
          </Loader>
        </Container>
      </Box>
      <Footer />
    </div>
  )
}

export default Layout

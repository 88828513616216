import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Candidate, Pod, Schedule } from '../react-app-env'
import type { RootState } from '../store'
import { equalInterviewTypes } from '../utils/cleanClasses'
import { InterviewType, ScheduleStatus } from '../api/types'

export interface addCandidatePayload {
  candidate: Candidate
  podId: string
}

export interface addCandidateListPayload {
  candidates: Candidate[]
  podId: string
}

export interface updateCandidateSchedulePayload {
  candidateId: string
  podId: string
  schedule: Schedule
}

export interface addCandidateSchedulePayload {
  candidateId: string
  podId: string
  schedule: Schedule
}

export interface setCandidateSchedulesPayload {
  candidateId: string
  podId: string
  schedules: Schedule[]
}

export interface removeCandidatePayload {
  candidateId: string
  podId: string
}

export interface updateScheduleStatusPayload {
  candidateId: string
  interviewType: InterviewType | InterviewType
  newStatus: ScheduleStatus
  podId: string
}

export interface replaceCandidatesPayload {
  candidates: Candidate[]
  podId: string
}

const initialState: Pod[] = [] // Initial Demo State @TODO - rewrite to pull from appsync down the road

export const podSlice = createSlice({
  name: 'pods',
  initialState,
  reducers: {
    clearPods: () => {
      return []
    },
    removePod: (state, action: PayloadAction<Pod>) => {
      return state.filter((item) => item.id !== action.payload.id)
    },
    createPod: (state, action: PayloadAction<Pod>) => {
      return [...state, action.payload]
    },
    addCandidate: (state, action: PayloadAction<addCandidatePayload>) => {
      return state.map((pod) => {
        if (pod.id === action.payload.podId) {
          pod.candidates.push(action.payload.candidate)
          return pod
        } else {
          return pod
        }
      })
    },
    removeCandidate: (state, action: PayloadAction<removeCandidatePayload>) => {
      state.map((pod) => {
        if (pod.id === action.payload.podId) {
          pod.candidates = pod.candidates.filter(
            (candidate) => candidate.candidateId != action.payload.candidateId
          )
          return pod
        } else {
          return pod
        }
      })
    },
    addCandidateList: (
      state,
      action: PayloadAction<addCandidateListPayload>
    ) => {
      const pod = state.find((pod) => pod.id == action.payload.podId)
      if (pod) {
        pod.candidates = [...pod.candidates, ...action.payload.candidates]
      }
    },
    updateCandidate: (state, action: PayloadAction<any>) => {
      const _candidates: Candidate[] = []
      return state.map((pod) => {
        if (pod.id === action.payload.id) {
          pod.candidates.map((candidate) => {
            if (candidate.candidateId === action.payload.candidateId) {
              _candidates.push({ ...candidate, name: action.payload.newName })
            } else {
              _candidates.push(candidate)
            }
          })
          return { ...pod, candidates: _candidates }
        } else {
          return pod
        }
      })
    },
    updateCandidateHireLink: (state, action: PayloadAction<any>) => {
      const _candidates: Candidate[] = []
      return state.map((pod) => {
        if (pod.id === action.payload.id) {
          pod.candidates.map((candidate) => {
            if (candidate.candidateId === action.payload.candidateId) {
              _candidates.push({
                ...candidate,
                hireLink: action.payload.hireLink,
              })
            } else {
              _candidates.push(candidate)
            }
          })
          return { ...pod, candidates: _candidates }
        } else {
          return pod
        }
      })
    },
    addCandidateSchedule: (
      state,
      action: PayloadAction<addCandidateSchedulePayload>
    ) => {
      const pods = [...state]
      const pod = pods.find((pod) => pod.id == action.payload.podId)
      const candidate = pod?.candidates.find(
        (candidate) => candidate.candidateId == action.payload.candidateId
      )
      let schedule = candidate?.schedules.find((schedule) =>
        equalInterviewTypes(
          schedule.interviewType,
          action.payload.schedule.interviewType
        )
      )

      if (candidate && schedule) {
        schedule = action.payload.schedule
      } else if (candidate) {
        candidate.schedules = [...candidate.schedules, action.payload.schedule]
      }

      return pods
    },
    setCandidateSchedules: (
      state,
      action: PayloadAction<setCandidateSchedulesPayload>
    ) => {
      const pod = state.find((pod) => pod.id == action.payload.podId)
      const candidate = pod?.candidates.find(
        (candidate) => candidate.candidateId == action.payload.candidateId
      )

      if (candidate) {
        candidate.schedules = action.payload.schedules
      }
    },
    updateCandidateSchedule: (
      state,
      action: PayloadAction<updateCandidateSchedulePayload>
    ) => {
      const pod = state.find((pod) => pod.id == action.payload.podId)
      const candidate = pod?.candidates.find(
        (candidate) => candidate.candidateId == action.payload.candidateId
      )
      if (candidate) {
        candidate.schedules = candidate.schedules.filter(
          (schedule) =>
            !equalInterviewTypes(
              schedule.interviewType,
              action.payload.schedule.interviewType
            )
        )
        candidate.schedules.push(action.payload.schedule)
      }
    },
    updateScheduleStatus: (
      state,
      action: PayloadAction<updateScheduleStatusPayload>
    ) => {
      const pod = state.find((pod) => pod.id == action.payload.podId)
      const candidate = pod?.candidates.find(
        (candidate) => candidate.candidateId == action.payload.candidateId
      )
      const schedule = candidate?.schedules.find((schedule) =>
        equalInterviewTypes(
          schedule.interviewType,
          action.payload.interviewType
        )
      )

      if (schedule) {
        schedule.scheduleStatus = action.payload.newStatus
      }
    },
    replaceCandidates: (
      state,
      action: PayloadAction<replaceCandidatesPayload>
    ) => {
      const pod = state.find((pod) => pod.id == action.payload.podId)
      if (pod) {
        pod.candidates = action.payload.candidates
      }
    },
  },
})

export const {
  clearPods,
  createPod,
  removePod,
  removeCandidate,
  addCandidate,
  addCandidateList,
  updateCandidate,
  updateScheduleStatus,
  updateCandidateHireLink,
  updateCandidateSchedule,
  replaceCandidates,
  setCandidateSchedules,
} = podSlice.actions
export const selectPods = (state: RootState): Pod[] => state.pods

export default podSlice.reducer

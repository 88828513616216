import { Chip } from '@mui/material'

const renderTimeRange = (startDate: Date, endDate: Date) => {
  return `${
    startDate.getHours() > 12 ? startDate.getHours() - 12 : startDate.getHours()
  }:${startDate.getMinutes() !== 0 ? startDate.getMinutes() : '00'}${
    startDate.getHours() > 12 ? 'PM' : 'AM'
  } - 
    ${endDate.getHours() > 12 ? endDate.getHours() - 12 : endDate.getHours()}:${
    endDate.getMinutes() !== 0 ? endDate.getMinutes() : '00'
  }${endDate.getHours() > 12 ? 'PM' : 'AM'}`
}

const renderJobLevel = (jobLevel: number) => {
  return (
    <Chip
      key={jobLevel}
      label={'L' + jobLevel}
      variant="outlined"
      style={{ marginRight: '3px' }}
    />
  )
}

const renderJobLevelDeletable = (jobLevel: number, handleDelete) => {
  return (
    <Chip
      key={jobLevel}
      label={'L' + jobLevel}
      variant="outlined"
      style={{ marginRight: '3px' }}
      onDelete={() => handleDelete(jobLevel)}
    />
  )
}

const renderCompetencies = (input: string[]) => {
  const sorted = [...input].sort()
  return sorted
}

const renderWithSpacing = (input: string) => {
  return input.replace(/([A-Z][a-z])/g, ' $1').trim()
}

export {
  renderJobLevel,
  renderJobLevelDeletable,
  renderTimeRange,
  renderCompetencies,
  renderWithSpacing,
}
